import { renderIcon } from "@/utils"
import { h } from "vue"
import { RouterLink } from "vue-router"
import { type MenuMixedOption } from "naive-ui/es/menu/src/interface"
import { useAuthStore } from "@/stores/auth"
import i18n from "@/utils/i18n"

const GearIcon = "ion:cog-outline"
const BrushIcon = "ion:brush-outline"
const CalendarIcon = "ion:calendar-outline"
const CreateIcon = "ion:create-outline"
// const SettingsIcon = "carbon:settings-adjust"
const BlankIcon = "carbon:document-blank"
const SendIcon = "ion:send-outline"
const DocumentIcon = "ion:document-outline"
const PeopleIcon = "ion:people-outline"
const OrganizationIcon = "carbon:enterprise"
const ExtrasIcon = "carbon:document-add"
const FinalBudgetIcon = "carbon:white-paper"
const CalendarNumberIcon = "ion:calendar-number-outline"
const PuzzleIcon ="ion:extension-puzzle-outline"
const VideoPlayerIcon = "carbon:video-player"
const BriefcaseIcon = "ion:briefcase-outline"
const DocumentsIcon = "ion:documents-outline"
const ListIcon = "ion:list-outline"
const AlbumsIcon = "ion:albums-outline"
const ColorWandIcon = "ion:color-wand-outline"
const SettingsIcon = "ion:settings-outline"
const BookIcon = "ion:book-outline"
const DocumentAttachIcon = "ion:document-attach-outline"
const HelpIcon = "ion:help-circle-outline"


export default function getItems(mode: "vertical" | "horizontal", collapsed: boolean, rol:Number, t): MenuMixedOption[] {
	const authStore = useAuthStore()
	// 1. admin 2. event planer 3. game master
	if(rol == 3)
	{
		return [
			{
				label: () =>
					h(
						RouterLink,
						{
							to: {
								name: "Calendar"
							}
						},
						{ default: () => t("menu.calendar") }
					),
				key: "Calendar",
				icon: renderIcon(CalendarNumberIcon)
			},
			{
				label: () =>
						h(
							RouterLink,
							{
								to: {
									name: "BookingList"
								}
							},
							{ default: () => t("menu.booking_list") }
						),
				key: "BookingList",
				icon: renderIcon(ListIcon)
			},
		]
	}
	if(rol == 1)
	{
		if( authStore.full_user.organization.plan_level == 'FULL')
		{
			return [
				
				{
					label: t("menu.sales"),
					key: "Ventas",
					icon: renderIcon(BriefcaseIcon),
					children: [
						{
							label: () =>
									h(
										RouterLink,
										{
											to: {
												name: "Budget"
											}
										},
										{ default: () => t("menu.budgets") }
									),
							key: "Budget",
							icon: renderIcon(DocumentsIcon)
						},
						{
							label: () =>
									h(
										RouterLink,
										{
											to: {
												name: "Client"
											}
										},
										{ default: () => t("menu.clients") }
									),
							key: "Client",
							icon: renderIcon(PeopleIcon)
						},
					]
				},
				{
					label: t("menu.bookings"),
					key: "booking",
					icon: renderIcon(CalendarIcon),
					children: [
						{
							label: () =>
									h(
										RouterLink,
										{
											to: {
												name: "BookingList"
											}
										},
										{ default: () => t("menu.booking_list") }
									),
							key: "BookingList",
							icon: renderIcon(ListIcon)
						},
						{
							label: () =>
									h(
										RouterLink,
										{
											to: {
												name: "Calendar"
											}
										},
										{ default: () => t("menu.calendar") }
									),
							key: "Calendar",
							icon: renderIcon(CalendarNumberIcon)
						},
					]
				},
				{
					label: () =>
						h(
							RouterLink,
							{
								to: {
									name: "Events"
								}
							},
							{ default: () => t("menu.activities") }
						),
					key: "Events",
					icon: renderIcon(AlbumsIcon)
				},
				{
					label: () =>
						h(
							RouterLink,
							{
								to: {
									name: "Demos"
								}
							},
							{ default: () => t("menu.demos") }
						),
					key: "Demos",
					icon: renderIcon(VideoPlayerIcon)
				},
				{
					label: () =>
						h(
							RouterLink,
							{
								to: {
									name: "Content"
								}
							},
							{ default: () => t("menu.content") }
						),
					key: "Content",
					icon: renderIcon(BrushIcon)
				},
				{
					label: () =>
						h(
							RouterLink,
							{
								to: {
									name: "Settings"
								}
							},
							{ default: () => t("menu.settings") }
						),
					key: "Settings",
					icon: renderIcon(ColorWandIcon)
				},
				{
					label: t("menu.settings_erp"),
					key: "Configuracion ERP",
					icon: renderIcon(SettingsIcon),
					children: [
						{
							label: () =>
									h(
										RouterLink,
										{
											to: {
												name: "organization"
											}
										},
										{ default: () => t("menu.organization") }
									),
							key: "organization",
							icon: renderIcon(OrganizationIcon)
						},
						{
							label: () =>
									h(
										RouterLink,
										{
											to: {
												name: "Actividades"
											}
										},
										{ default: () => t("menu.activities_erp") }
									),
							key: "Actividades",
							icon: renderIcon(BookIcon)
						},
						{
							label: () =>
									h(
										RouterLink,
										{
											to: {
												name: "extras"
											}
										},
										{ default: () => t("menu.extras") }
									),
							key: "extras",
							icon: renderIcon(DocumentAttachIcon)
						},
						{
							label: () =>
									h(
										RouterLink,
										{
											to: {
												name: "finalBudget"
											}
										},
										{ default: () => t("menu.final_page_budget") }
									),
							key: "finalBudgetPage",
							icon: renderIcon(DocumentAttachIcon)
						},
						{
							label: () =>
									h(
										RouterLink,
										{
											to: {
												name: "QuestionBookingList"
											}
										},
										{ default: () => t("menu.activities_questions") }
									),
							key: "QuestionBookingList",
							icon: renderIcon(HelpIcon)
						},
					]
				},
			]
		}
		if( authStore.full_user.organization.plan_level == 'ONLY_ACTIVITIES')
			{
				return [
					
					{
						label: () =>
							h(
								RouterLink,
								{
									to: {
										name: "Events"
									}
								},
								{ default: () => t("menu.activities") }
							),
						key: "Events",
						icon: renderIcon(AlbumsIcon)
					},
					{
						label: () =>
							h(
								RouterLink,
								{
									to: {
										name: "Demos"
									}
								},
								{ default: () => t("menu.demos") }
							),
						key: "Demos",
						icon: renderIcon(VideoPlayerIcon)
					},
					{
						label: () =>
							h(
								RouterLink,
								{
									to: {
										name: "Content"
									}
								},
								{ default: () => t("menu.content") }
							),
						key: "Content",
						icon: renderIcon(BrushIcon)
					},
					{
						label: () =>
							h(
								RouterLink,
								{
									to: {
										name: "Settings"
									}
								},
								{ default: () => t("menu.settings") }
							),
						key: "Settings",
						icon: renderIcon(ColorWandIcon)
					},
					{
						label: t("menu.settings_erp"),
						key: "Configuracion ERP",
						icon: renderIcon(GearIcon),
						children: [
							{
								label: () =>
										h(
											RouterLink,
											{
												to: {
													name: "organization"
												}
											},
											{ default: () => t("menu.organization") }
										),
								key: "organization",
								icon: renderIcon(OrganizationIcon)
							}	
						]
					},
				]
			}
	}
}

import { axiosToken, simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";
import { defineStore, acceptHMRUpdate } from "pinia"
import {type ActionData} from "@/stores/apps/interfaces/action_interfaces"
import { useContentStore } from "./content";
import { useEventsStore } from "./events";
import dayjs from "@/utils/dayjs";
import _ from "lodash";
import { sound_alert } from  "@/utils/notifications";
import { getTranslatedObject } from "@/utils/translate";
export const useActionStore = defineStore("action", {
	state: () => ({
        actionData: {} as ActionData, 
		forceQuiz:[],
		challenges: [], 
		current_action_team:null,
		showAnswer: false,
		answersShuffled: [],
		userAnswer: null,
		quizSelected: null,
		challengeSelected: null,
		ws : null, 
		activeChallenge: null,
		teamChallenges: [],
		playerChallenges: [],
		actionChallengeToCheck: [],
		team: [],
		
	}),
	actions: {
		// Change rnaking
		setChangeRanking(event_api_key:string, status:string)
		{
			simpleAxios("put", "events/event_full/" + event_api_key, {
				ranking_mode: status,
			})
		},
		async updateTeam(team)
		{
			if( this.team.length == 0)
			{
				return
			}
			const indexUpdate = this.team.findIndex(e => e.team.id == team.id)
			this.team[indexUpdate].team = team;
		},
		async getActionData (api_key:string)
		{
			await simpleAxios("get", "events/event_full/" + api_key, {})
			.then((response: AxiosResponse) => {
                console.log(response.data);
                
                this.actionData = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async setActionData (data:ActionData)
		{
            this.actionData = data
		},
		async setChallenges(data:any)
		{
			const foundIndex = this.playerChallenges.findIndex(x => x.id == data.id);
			if ( foundIndex >= 0 )
			{
				this.playerChallenges[foundIndex] = data;
			}
			else
			{
				this.playerChallenges.push(data)		
			}	
		},
		async fetchChallenges(api_key:string)
		{
			const contentStore = useContentStore()
			await simpleAxios("get", "ActionChallenge/get_challenge/" + api_key, {})
			.then((response: AxiosResponse) => {
				console.log(response.data);
				this.playerChallenges = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async fetchTeamChallenges(api_key:string)
		{
			await simpleAxios("get", "ActionChallenge/get_challenge/" + api_key, {})
			.then((response: AxiosResponse) => {
				console.log(response.data);
				this.teamChallenges = response.data.filter(e => e.team == this.current_action_team.id)
				// this.setTeamChallenges(response.data)
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async setTeamChallenges(data:any)
		{
			const foundIndex = this.teamChallenges.findIndex(x => x.id == data.id);
			if ( foundIndex >= 0 )
			{
				this.teamChallenges[foundIndex] = data;
			}
			else
			{
				if(this.current_action_team)
				{
					if( data.team == this.current_action_team.id)
					{
						const findChallenge = this.teamChallenges.findIndex(x => x.challenge == data.challenge)
						
						if(findChallenge >= 0)
						{
							this.teamChallenges[findChallenge] = data;
						}
						else
						{
							this.teamChallenges.push(data)
						}
					}
				}
			}
		},
		async fetchForceQuiz (api_key:string)
		{
			await simpleAxios("get", "ActionChallenge/get_force_quiz/" + api_key, {})
			.then((response: AxiosResponse) => {
                console.log(response.data);
                
                this.forceQuiz = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		setChallengeDone( active_challenge_id)
		{
			const challenge = this.teamChallenges.find(e => e.id == active_challenge_id)
			challenge.done = true
		},
		setChallengeInitDone( active_challenge_id)
		{
			this.teamChallenges.push({challenge: active_challenge_id, done: true})
		},
		updateActionChallengeToCheck(data:any)
		{
			
			this.actionChallengeToCheck = data;
		},
	},
	persist: {
		// paths: ["events"]
	},
	getters:{
		getSecondsTime: (state) =>{
			if(state.actionData.timer_finish == undefined)
			{
				return;
			}
			const now:Date = new Date();			
			return new Date(state.actionData.timer_finish);
		},
		getNumberQuiz: state=> (id:number) =>{
			const contentStore = useContentStore();
			const categoryInfo = {
				total:0,
				done:0 
			}
			
			if( !('action_challenge_quiz' in contentStore.content))
			{
				return categoryInfo;
			}
			if( state.current_action_team == null )
			{
				return categoryInfo;
			}
			contentStore.content.action_challenge_quiz.forEach(e => {
				if( e.category == id )
				{
					categoryInfo.total ++;
					if(state.current_action_team.challenge_quiz.includes(e.id))
					{
						categoryInfo.done ++;
					}
				}
			});
			return categoryInfo;
		},
		getQuizFromCategory: state=> (id:number) =>{
			const contentStore = useContentStore();
			if( !('action_challenge_quiz' in contentStore.content))
			{
				return [];
			}
			
			return contentStore.content.action_challenge_quiz.filter(e=>  e.category == id).sort((a,b) => a.name.localeCompare(b.name))
		
		},
		getQuizDone: state=> (id:number) =>{
			if( state.current_action_team == null )
			{
				return false;
			}
			if( state.current_action_team.challenge_quiz.includes(id))
			{
				return true;
			}
			return false;
		},
		getActiveChallenge: (state) =>{
			const contentStore = useContentStore();
			if(state.teamChallenges.length == 0)
			{
				return null
			}

			const challenge = state.teamChallenges.find(e => e.returned == false)
			if( challenge)
			{				
				challenge.data = contentStore.getContentByTypeById("action_challenge_challenge", challenge.challenge)
			}
			return challenge
		},
		getChallengeStatus: state=> (id:number) =>{
			const contentStore = useContentStore();
			let status = 'ready';
			
			const challenge_data = contentStore.getContentByTypeById("action_challenge_challenge", id)
			if(state.current_action_team.money < challenge_data.cost)
			{
				status = "no_money"
			}
			else if(id.toString() in state.actionData.challenge_store && state.actionData.challenge_store[id] <= 0)
			{
				status = "no_material"
			}

			const challenge = state.teamChallenges.find(e => e.challenge == id)
			if(challenge != undefined)
			{
				
				if(challenge.returned && challenge.game_master_check)
				{
					status = "done"	
				}
				if(challenge.returned && !challenge.game_master_check)
				{
					status = "no_review"	
				}
			}
			
			return status
		},
		getChallengeInitStatus: state=> (id:number) =>{
			let status = 'ready';

			const challenge = state.teamChallenges.find(e => e.challenge == id)
			if(challenge != undefined)
			{
				if(challenge.returned && challenge.game_master_check)
				{
					status = "done"	
				}
				if(challenge.returned && !challenge.game_master_check)
				{
					status = "no_review"	
				}
			}
			
			return status
		},
		getActionChallengeToCheck: (state) =>{
			let challengeToCheck =[]
			state.playerChallenges.forEach(async(ch) =>{
				if( ch.done == true && ch.game_master_check == false)
				{
					const team = state.team.find(e => e.id == ch.team)	
					if( team != undefined)
					{
						challengeToCheck.push(
							{
								challenge:ch,
								team_name:team.team.name_id + " - " + team.team.name,
								team_id: team.id
							}
						);

					}
										
				}
			})			
			return challengeToCheck;
		},
		getTotalPoints: state =>{
			let points = 0
			state.team.forEach(e=> {
				if(e.team)
				{
					points += e.team.points
				}
			})
			return points
		},
		getChallengesNSelect: state =>{
			let options = []
			
			state.playerChallenges.forEach((e)=>{
				options.push({
					label:e.name ,
					value:e.id,
				}
				)
			})
			return options
		},
		getItemsToPick : state => () =>{
			const storeEvents = useEventsStore()
			const storeContent = useContentStore()

			console.log(state.actionData.store_queue);
			
			if(state.actionData.store_queue.length ==  0 || state.team.length == 0)
			{
				return []
			}
			const itemsToPick = [] as Array<Object>
			let custom_id = 0
			state.actionData.store_queue.forEach(e =>{
				const team = state.team.find(team => team.id == e.team_id)
				const item = storeContent.getContentByTypeById('action_item', e.item_id)
				itemsToPick.push({
					"team_name":team.team.name_id + " - " + team.team.name,
					"item_name": getTranslatedObject(item,'name' ),
					"item_id": e.item_id,
					"team_id": e.team_id,
					custom_id,

				})
				custom_id ++;

			})
			return itemsToPick
		},
		getDataPick: state => () => {			
			const storeEvents = useEventsStore()
			const storeContent = useContentStore()

			if(state.playerChallenges.filter(e => e.picked == false).length == 0 || Object.keys(state.playerChallenges.filter(e => e.picked == false)).length == 0)
			{				
				return []
			}
			
			const challengesToPick = [] as Array<Object>
			console.log(state.playerChallenges);
			
			state.playerChallenges.filter(e => e.picked == false).forEach(oq => {
				if(state.team.find(t => t.id == oq.team) != undefined)
				{
					const team = storeEvents.getTeambyId(state.team.find(t => t.id == oq.team).team.id)
					const challenge = storeContent.getContentActionChallenge(parseInt(oq.challenge))
					console.log(oq);
					
					challengesToPick.push({team: team, challenge: challenge, id: oq.id, team_name: team.name, item_name: challenge.name, picked: challenge.picked, team_name_with_id: team.name_id + ". " + team.name})	
				}
			});

			return challengesToPick
		},
		getDataReturn: state => () => {
			const storeEvents = useEventsStore()
			const storeContent = useContentStore()

			if(state.playerChallenges.filter(e => e.returned == false && e.done == true).length == 0 || Object.keys(state.playerChallenges.filter(e => e.returned == false && e.done == true)).length == 0)
			{
				return []
			}
			
			const challengesToPick = [] as Array<Object>
			
			state.playerChallenges.filter(e => e.returned == false && e.done == true).forEach(oq => {
				const team = storeEvents.getTeambyId(state.team.find(t => t.id == oq.team).team.id)
				const challenge = storeContent.getContentActionChallenge(parseInt(oq.challenge))
				challengesToPick.push({team: team, challenge: challenge, id: oq.id, team_name: team.name, item_name: challenge.name, returned: challenge.returned, team_name_with_id: team.name_id + ". " + team.name})
			});

			return challengesToPick
		},
		getDataPublicRanking: state => () =>
		{
			if(state.team.length == 0)
			{
				return []
			}
			console.log("calling");
			const teamData = [...state.team]
			// return teamData

			return teamData.sort((a, b) => b.team.points - a.team.points || b.money - a.money )
		},
		getDataGameMasterRanking: state => () => {
			const eventStore = useEventsStore()
			const contentStore = useContentStore() 
			const dataTeam = JSON.parse(JSON.stringify(state.team));
			const ranking = dataTeam.sort((a, b) => b.team.points - a.team.points || b.money - a.money)
			console.log(ranking);
			
			const categories = []
			contentStore.content.category_quiz.forEach(e => {
				e.total_quizzes = contentStore.content.action_challenge_quiz.filter(ac => ac.category == e.id).length
				e.total_player = 0
				categories.push(e)
			});

			ranking.forEach(r => {
				let challenges_done = 0
				let quizzes_done = 0
				let last_challenge = ""
				let last_challenge_start = null
				const challenges_status = []
				console.log(r)
				state.playerChallenges.sort((a, b) => a.team_id - b.team_id).forEach(pc => {
					if(pc.team == r.id)
					{
						let challenge_data = undefined
						challenge_data = contentStore.content.action_challenge_challenge.find(ac => ac.id == pc.challenge)
							
						if(pc.done)
						{
							challenges_done++
							last_challenge = challenge_data.name
						}
						
						pc.data = challenge_data
						challenges_status.push(pc)
					}
				})
				const player_categories = JSON.parse(JSON.stringify(categories));
				const dataChallengeQuiz = JSON.parse(JSON.stringify(contentStore.content.action_challenge_quiz))
				r.challenge_quiz.forEach(e => {
					const quiz = dataChallengeQuiz.find(ac => ac.id == e)
					const category = player_categories.find(c => c.id == quiz.category )
					category.total_player++
					quizzes_done++
				});
				r.player_categories = player_categories
				r.challenges_status = challenges_status
				r.full_name = r.team.name_id + ". " + r.team.name
				r.challenges_done = challenges_done
				r.quizzes_done = quizzes_done
				r.last_challenge = last_challenge
				r.points = r.team.points
				r.key = r.id
			});

			return ranking
		},
		getChallengesWithStatus: state => () => {
			const contentStore = useContentStore()
			const actionStore = useActionStore()
			if(!('action_challenge_challenge' in contentStore.content))
			{
				return []
			}

			const challenges = contentStore.content.action_challenge_challenge.filter(e => e.init_challenge == false)
			
			challenges.forEach(element => {
				element.status = actionStore.getChallengeStatus(element.id)
			});
			return challenges
		},
		getChallengesInitWithStatus: state => () => {
			const contentStore = useContentStore()
			const actionStore = useActionStore()
			if(!('action_challenge_challenge' in contentStore.content))
			{
				return []
			}

			const challenges = contentStore.content.action_challenge_challenge.filter(e => e.init_challenge == true)
			
			challenges.forEach(element => {
				element.status = actionStore.getChallengeInitStatus(element.id)
			});
			return challenges
		},
		getTeamRanking: state => (sortByPoints = true): any | undefined => {
			let ranking = []
			if(sortByPoints)
			{
				ranking = state.team.sort((a, b) => b.team.points - a.team.points || b.money - a.money)
			}
			else
			{
				ranking = state.team.sort((a, b) => b.money - a.money || b.team.points - a.team.points)
			}
			console.log(ranking);
			
			let pos = 1
			let beforePoints = -1
			ranking.forEach(r => {
				if(beforePoints != -1 && beforePoints != r.team.points)
				{
					pos++
				}
				beforePoints = r.team.points
				r.position = pos
				r.points = r.team.points
				
			});
			console.log(ranking);
			
			return ranking
		},
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useActionStore, import.meta.hot))
}

import it from "./it"
import en from "./en"
import es from "./es"
import fr from "./fr"
import de from "./de"
import jp from "./jp"

export default {
	en,
	it,
	fr,
	de,
	es,
	jp
}

import nextRound from "@/assets/audios/next_round.mp3";
import challenge from "@/assets/audios/challenge.wav";
import chat from "@/assets/audios/chat.wav";
import finish_challange from "@/assets/audios/finish_challange.wav";
import correct from "@/assets/audios/correct.wav";
import incorrect from "@/assets/audios/incorrect.wav";
import material_pick from "@/assets/audios/material_pick.wav";
import material_return from "@/assets/audios/material_return.wav";
import challenge_to_review from "@/assets/audios/challenge_to_review.wav";


const sounds ={
    challenge,
    chat,
    finish_challange,
    correct,
    incorrect,
    material_pick,
    material_return,
    challenge_to_review
}


function notificate() {
    try{
        navigator.vibrate([200, 500, 200])
    }
    catch{
        // not soported 
    }
    const player = document.getElementById("generalAudioPlayer");
    player.src = (nextRound);
    player.play();
}


function sound_alert(sound) {
    try{
        navigator.vibrate([1000, 500, 1000, 500, 2000]);
    }
    catch{
        // not soported 
    }

    const player = document.getElementById("generalAudioPlayer");
    player.src = (sounds[sound]);
    player.play();
}


export { notificate, sound_alert };

<template>
	<div class="page">
        <div class="card-css page-header rounded-md py-6 px-3">
                <div class="flex flex-row justify-between gap-4 items-center w-full">
                    <h1>{{$t('content.content')}}</h1>
                </div>
        </div>
        <div class="grid grid-cols-3 activity-content gap-8">
            <div @click="openEdit(activity.name_id)" class="flex justify-center items-center p-3 h-28 hover:bg-primary bg-white rounded-3xl cursor-pointer" v-for="activity in generalActivities" :key="activity.id">
                <span>{{activity.name}}</span>
            </div>
        </div>  
	</div>
</template>

<script lang="ts" setup>
import { NButton, NSpace } from "naive-ui"
import { type LabelPlacement, type Size } from "naive-ui/es/form/src/interface"
import { ref } from "vue"
import { useContentStore } from "@/stores/apps/content.ts";
import { useRouter } from "vue-router";
import { useStoreI18n } from "@/composables/useStoreI18n";

const { t } = useStoreI18n()
const router = useRouter()
const contentStore = useContentStore();
const generalActivities = ref([{id: 1, name: t("general.ipad_title"), name_id:"ipad"}, {id: 2, name: t("general.tapas_title"), name_id:"tapas" }, {id: 3, name: t("general.utq_title"), name_id:"utq"}, {id: 4, name: t("general.action_title"), name_id:"action_challenge"}])
const openEdit = (name_id:string) => 
{
    router.push("/content/" + name_id)
}
</script>

<style lang="scss" scoped>
.components-list {
	grid-template-columns: none;
} 
.card-css {
    background-color: var(--bg-color);
}
</style>

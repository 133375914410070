<template>
    <span>
        <div class="page">
            <div class="grid grid-cols-2 gap-2">
                <div class="flex flex-col gap-2 ">
                    <div class="flex flex-col items-center justify-center h-[530px] card-css relative">
                        <n-form
                            ref="formRef"
                            :model="storeEvents.activityConfiguration"
                            :rules="rules"
                            :size="size"
                            :show-label="true"
                        >
                            <n-form-item 
                                :label="$t('gm.dashboards.template')" 
                                path="ipad_settings"
                                :validation-status="storeEvents.activityConfiguration.ipad_settings == '0' ? 'warning' : 'success'"
                                :feedback="storeEvents.activityConfiguration.ipad_settings == '0' ? $t('gm.dashboards.warning_template') : ''"
                            >
                            <div class="flex flex-col gap-2 w-full">
                                <n-alert v-if="ipadSettingWarning" type="warning">
                                    <h3>{{$t('dialogs.warning_change_template')}}</h3>
                                </n-alert>
                                <n-select 
                                    v-model:value="storeEvents.activityConfiguration.ipad_settings"
                                    :placeholder="$t('gm.dashboards.placeholder_template')"
                                    :options="ipadSettings"
                                    @update:value="setIpadSettings"
                                >
                                </n-select>
                            </div>
                            </n-form-item>
                            <n-grid :x-gap="24">
                                <n-form-item-gi :span="10" :label="$t('gm.dashboards.latitude')" path="latitude">
                                    <n-input-number :disabled="true" v-model:value="storeEvents.activityConfiguration.latitude" clearable />
                                </n-form-item-gi>
                                <n-form-item-gi  :span="10" :label="$t('gm.dashboards.longitude')" path="longitude">
                                    <n-input-number :disabled="true" v-model:value="storeEvents.activityConfiguration.longitude" clearable />
                                </n-form-item-gi>
                                <n-form-item-gi :span="4">
                                    <n-button v-if="addMarkerActive != true"  type="info" @click="addMarkerActive = true" ghost>
                                        <Icon :size="20" :name="LocateIcon"/>
                                    </n-button>
                                    <n-button  v-else type="info" @click="addMarkerActive = false">
                                        <Icon :size="20" :name="LocateIcon"/>
                                    </n-button>
                                </n-form-item-gi>
                            </n-grid>
                            <n-form-item :label="$t('gm.dashboards.duration_seconds')" path="duration">
                                <n-input-number v-model:value="storeEvents.activityConfiguration.duration" clearable />
                            </n-form-item>
                        </n-form>
                    </div>
                </div>
                <div class="relative flex w-full justify-center h-full">
                    <input type="search" ref="addressInput" class="controls absolute mb-2 bottom-0 rounded-lg border-2 border-teal-400 p-2 z-20 w-9/12" :placeholder="$t('gm.dashboards.placeholder_search_address')" />
                    <div ref="mapContainer" class="flex flex-col card-css w-full"></div>
                </div>
            </div>
        </div>
    </span>
</template>

<script lang="ts" setup>
import Icon from "@/components/common/Icon.vue"
import { NButton, NSelect, NForm, NFormItem, NInputNumber,  NFormItemGi, NGrid, useMessage, NModal, NAlert} from "naive-ui"
import {shallowRef, ref, h, onMounted, onBeforeMount, watch, toRaw, computed } from "vue"
import { type LabelPlacement, type Size, FormInst } from "naive-ui/es/form/src/interface"
import { useRoute, useRouter } from "vue-router"
import { axiosToken, fetchPromiseAll } from "@/utils/http";
import type { AxiosResponse } from "axios";
import { useEventsStore } from "@/stores/apps/events"
import { useUTQStore } from "@/stores/apps/utq"
import dayjs from "@/utils/dayjs"
import {play, pause, resume,} from "@/utils/timer_actions"
import GeneralTimer from "@/customComponents/timer/GeneralTimer.vue"
import { utqWs } from "@/utils/ws/utq_ws"
import IpadHunt from "@/views/ActivityCRUD/IpadHunt.vue"
import { Loader } from "@googlemaps/js-api-loader"
import { useContentStore } from "@/stores/apps/content"
import { useIpadHuntStore } from "@/stores/apps/ipad_hunt"
import { useStoreI18n } from "@/composables/useStoreI18n"

const { t } = useStoreI18n()
const storeContent = useContentStore()
const storeIpad = useIpadHuntStore()

const templateOldValue = ref(null)

const size = ref<Size | undefined>("large")
const rules = {
            ipad_settings: {
                required: false,
                trigger: ['blur'],
                message: t('gm.dashboards.placeholder_template')
            },
            latitude: {
                type: "number",
                required: true,
                trigger: ['blur', 'change', 'update'],
                message: t('gm.dashboards.select_latitude')
            },
            longitude: {
                type: "number",
                required: true,
                trigger: ['blur', 'change', 'update'],
                message: t('gm.dashboards.select_longitude')
            },
            duration: {
                type: "number",
                required: true,
                trigger: ['blur', 'change'],
                message: t('gm.dashboards.put_duration')
            },
        }


const route = useRoute()
const LocateIcon = "ion:locate-outline"

const map = shallowRef<google.maps.Map | null>(null);
const mapContainer = ref<HTMLDivElement | null>(null);

const addMarkerActive = ref(false)
const initMarker = ref(null)

const addMarker = async (pos) => 
{
    const { InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
    const infoWindow = new InfoWindow();

    const pinElement = new PinElement({
        glyph: "Posición inicial",
        background: 'red',
        borderColor: 'black',
        scale:1
    })
    const draggableMarker  = new AdvancedMarkerElement({
        position: pos,
        title: t('gm.dashboards.initial_position'),
        map: toRaw(map.value),
        gmpDraggable: true,
        content: pinElement.element,
    })

    draggableMarker.addListener('dragend', (event) => {
        let position = draggableMarker.position as google.maps.LatLng;
        infoWindow.close();
        storeEvents.activityConfiguration.lat = position.lat
        storeEvents.activityConfiguration.lng = position.lng
        formRef.value?.validate()
    });
    initMarker.value = draggableMarker
}

const initIpadSetting = ref(null)
const ipadSettingWarning = ref(false)
const setIpadSettings = () => 
{
    if(route.params.api_key !== undefined)
    {
        if(storeEvents.activityConfiguration.ipad_settings == initIpadSetting.value)
        {
            ipadSettingWarning.value = false
        }
        else
        {
            ipadSettingWarning.value = true
        }
    }
}


const firstSave = ref(false)

const addressInput = ref(null);
async function loadMap() {
  const loader = new Loader({
    apiKey: 'AIzaSyD2kqXl2K4vAkTcAtskxZW12tyOtUYKvHo',
    version: 'weekly',
    libraries: ["places"],
  });

  loader.load().then( async () => {
    if (mapContainer.value) {
        const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        const Places = await loader.importLibrary("places") as google.maps.PlacesLibrary;
        let center = { lat: 40.374189, lng: 2.2847488443187474 }
        if( storeEvents.activityConfiguration.latitude !== null)
        {
            center = { lat: storeEvents.activityConfiguration.latitude, lng: storeEvents.activityConfiguration.longitude }
        }
        map.value = new Map(mapContainer.value, {
            center: center,
            zoom: 6,
            mapId: "4504f8b37365c3d0"
        });

        // Create a bounding box with sides ~10km away from the center point
        const defaultBounds = {
            north: center.lat + 0.1,
            south: center.lat - 0.1,
            east: center.lng + 0.1,
            west: center.lng - 0.1,
        };

        const options = {
            bounds: defaultBounds, //optional
            types: [], //optioanl
            componentRestrictions: { country: "es" }, //limiter for the places api search
            fields: ["address_components", "geometry", "icon", "name"], //allows the api to accept these inputs and return similar ones
            strictBounds: false, //optional
        };

        const autocomplete = new Places.Autocomplete(addressInput.value, options);

        console.log('autocomplete', autocomplete);

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace(); //this callback is inherent you will see it if you logged autocomplete
            map.value.setCenter(new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()));
            map.value.setZoom(18)
            console.log('place', place);
        });

        map.value.addListener("click", (mapsMouseEvent) => {
            placeMarker({lat: mapsMouseEvent.latLng.lat(), lng: mapsMouseEvent.latLng.lng()})
        });
    } else {
      console.error("Map container element is null.");
    }
  });
}

const deleteMarker = async () => {
    initMarker.value.setMap(null)
}

const placeMarker = async (pos) => {
    if(addMarkerActive.value)
    {
        if(initMarker.value != null)
        {
            deleteMarker()
        }
        addMarker(pos)
        storeEvents.activityConfiguration.latitude = pos.lat
        storeEvents.activityConfiguration.longitude = pos.lng
        addMarkerActive.value = false
        formRef.value?.validate()
    }
}

const ipadSettings = computed(() => { 
    if(storeContent.content.ipad_settings != undefined) 
    {
        let is = storeContent.content.ipad_settings
        is.unshift({id: "0", name: t('gm.dashboards.not_template')})
        return is.map((v) => ({ value: v.id.toString(), label: v.name }))
    }
    else
    {
        return [{value:"0", label: t('gm.dashboards.not_template')}]
    }
})

const loadIpadForm = () => 
{
    if(storeIpad.ipadData.ipad_settings != null)
    {
        storeEvents.activityConfiguration.ipad_settings = storeIpad.ipadData.ipad_settings.id.toString()
    }
    else
    {
        storeEvents.activityConfiguration.ipad_settings = "0"
    }

    templateOldValue.value = storeEvents.activityConfiguration.ipad_settings

    storeEvents.activityConfiguration.duration = storeIpad.ipadData.duration
    if(storeIpad.ipadData.latitude == null)
    {
        storeEvents.activityConfiguration.latitude = null
        storeEvents.activityConfiguration.longitude = null
        firstSave.value = true
    }
    else
    {
        storeEvents.activityConfiguration.latitude = parseFloat(storeIpad.ipadData.latitude)
        storeEvents.activityConfiguration.longitude = parseFloat(storeIpad.ipadData.longitude)
        addMarker({lat: storeEvents.activityConfiguration.latitude, lng: storeEvents.activityConfiguration.longitude})
    }
}
const formRef = ref<FormInst | null>(null)
const storeEvents = useEventsStore()
onMounted( async() => {
    storeEvents.activityConfiguration = {latitude: null, longitude: null, ipad_settings: null, duration: null, activity_formRef: formRef}
    if(route.params.api_key)
    {
        await fetchPromiseAll([
            storeIpad.getIpadHuntData(String(route.params.api_key)),
            storeContent.getAllContent("ipad_settings")
        ])
        initIpadSetting.value = storeEvents.activityConfiguration.ipad_settings
    }
    else
    {
        storeContent.getAllContent("ipad_settings")
    }
    
    if (mapContainer.value) {
       await loadMap();
       if(route.params.api_key)
        {
            loadIpadForm();
        }
    }
})

</script>

<style lang="scss" scoped>
.components-list {
	grid-template-columns: none;
}
.card-css {
    background-color: var(--bg-color);
}
</style>

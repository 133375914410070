const meta = document.createElement("meta")
meta.name = "naive-ui-style"
document.head.appendChild(meta)

import { createApp } from "vue"
import { createPinia } from "pinia"
import { createI18n } from "vue-i18n"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"

import App from "@/App.vue"
import router from "@/router"
import { type Locales, type MessageSchema, getI18NConf } from "@/lang/config"
import * as Sentry from "@sentry/vue";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import { useNavigationStore } from "./stores/navigation"


const pinia = createPinia()
// pinia.use(piniaPluginPersistedstate)

const i18n = createI18n<MessageSchema, Locales>(getI18NConf())

const app = createApp(App)

if( process.env.NODE_ENV == 'production')
{
    Sentry.init({
        app,
        dsn: "https://82351a3bf16438a3dc15293d18398de7@o4507249627299840.ingest.de.sentry.io/4507249629069392",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    
}




app.use(pinia)
// Global Navigation Guard
router.beforeEach((to, from, next) => {
  const navigationStore = useNavigationStore();

  // If no history, clear it (indicates direct access)
  // if (navigationStore.isDirectAccess) {
  //   navigationStore.clearHistory();
  // }

  // Add the current route to the navigation history
  navigationStore.addRoute(to.fullPath);

  next();
});

app.use(i18n as any)
app.use(router)
app.component('QuillEditor', QuillEditor)
app.component('vue-cal', VueCal);
app.mount("#app")
// myUndefinedFunction();

import { axiosToken, simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";
import { defineStore, acceptHMRUpdate } from "pinia"
import { useContentStore } from './content'
import { type TeamTapasData} from "@/stores/apps/interfaces/event_interfaces"
import { useStoreI18n } from "@/composables/useStoreI18n";
import i18n from "@/utils/i18n";
type Team = {
	name: string
	position: string
	points: number
	challange_a: string
	challange_b: string
	challange_c: string
	challange_d: string
	challange_e: string
	challange_f: string
}

type tr = {name:string, pos:string, points:number}

type Challenge = {
	id: number, 
	name: string, 
	description: string, 
	image: string, 
	material: string, 
	scoring_description: string
	ranking: tr[]
}

export const useEventsStore = defineStore("events", {
	state: () => ({
		events: [],
		teams: [],
		current_team:{},
		gmView: false,
		eventSelected: {id: 0, name: null, activity: {id: null, name: null, code: null}, comments: null, date: null, challenges: [], style: undefined, api_key: undefined, ranking: [], show_timer: false, logo: null, finished: null, language: "Español", organization: null, demo: false},
		challengeSelected: {id: 0, name: null, description: null, image: null, material: null, scoring_description: null, ranking: []},
		tapas_team:[] as TeamTapasData[],
		current_tapas_team:{} as TeamTapasData,
		ipad_team:[] ,
		current_ipad_team:{},
		global_loading: false,
		showModalLogout: false,
		showModalChangeTeamName: false,
		eventNotFound:false,
		activityConfiguration: {},
	}),
	actions: {
		setDefault () 
		{
			this.events= []
			this.teams= []
			this.current_team={}
			this.gmView= false
			this.eventSelected= {id: 0, name: null, activity: {id: null, name: null, code: null}, comments: null, date: null, challenges: [], style: undefined, api_key: undefined, ranking: [], show_timer: false, logo: null, finished: null, language: "Español", organization: null, demo: false},
			this.challengeSelected= {id: 0, name: null, description: null, image: null, material: null, scoring_description: null, ranking: []},
			this.tapas_team=[] as TeamTapasData[]
			this.current_tapas_team={} as TeamTapasData
			this.ipad_team=[] 
			this.current_ipad_team={}
			this.global_loading= false
			this.showModalLogout= false
			this.showModalChangeTeamName= false
			this.eventNotFound=false
			this.activityConfiguration= {}
        },
        setEvents(data:any)
        {
            this.events = data
        },
		setTeam(api_key:any)
		{
			this.current_team = this.teams.find( (e)=>e.api_key == api_key)	
		},
		setTapasTeamData(data)
		{

		},
		async getEvents ()
		{
			await axiosToken("get", "events/event/", {})
			.then((response: AxiosResponse) => {
				console.log(response.data);
				
				this.events = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		
		async getEventsByOrganization (api_key_organization:String)
		{
			await simpleAxios("get", "events/event/?organization=" + api_key_organization, {})
			.then((response: AxiosResponse) => {
				console.log(response.data);
				
				this.events = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},

		async getEvent(api_key:string)
		{
			await simpleAxios("get", "events/event/" + api_key, {})
			.then((response: AxiosResponse) => {
				console.log(response.data);
				
				this.eventSelected.activity = response.data.activity
				this.eventSelected.api_key = response.data.api_key
				this.eventSelected.comments = response.data.comments
				this.eventSelected.date = response.data.date
				this.eventSelected.id = response.data.id
				this.eventSelected.style = response.data.style
				this.eventSelected.name = response.data.name
				this.eventSelected.logo = response.data.logo
				this.eventSelected.finished = response.data.finished
				this.eventSelected.language = response.data.language
				this.eventSelected.organization = response.data.organization
				this.eventSelected.demo = response.data.demo
			})
			.catch((e: any) => {
				if(e.code == "ERR_BAD_REQUEST")
				{
					this.eventNotFound = true;
				}
				console.log(e.code);
			});
		},

		async getEventFull(api_key:string)
		{
			await axiosToken("get", "events/event_full/" + api_key, {})
			.then((response: AxiosResponse) => {
				console.log(response);
				
				this.eventSelected.activity = response.data.event.activity
				this.eventSelected.api_key = response.data.event.api_key
				this.eventSelected.comments = response.data.event.comments
				this.eventSelected.date = response.data.event.date
				this.eventSelected.id = response.data.event.id
				this.eventSelected.style = response.data.event.style
				this.eventSelected.name = response.data.event.name
				this.eventSelected.challenges = response.data.challenges
				this.eventSelected.show_timer = response.data.show_timer
				this.eventSelected.logo = response.data.logo
				this.eventSelected.finished = response.data.finished
				this.eventSelected.language = response.data.language
				this.eventSelected.organization = response.data.organization
				this.eventSelected.demo = response.data.demo
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async getTeams(api_key:string)
		{
			await simpleAxios("get", "events/event/team/" + api_key , {})
			.then((response: AxiosResponse) => {
				this.teams = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async updateTeam(team:any)
		{
			let index = this.teams.findIndex(e => e.id == team.id )
			this.teams[index] = team;
			
			if(team.id == this.current_team.id)
			{
				this.current_team = team	
			}
		},

		clearEventSelected()
		{
			this.eventSelected = {id: 0, name: null, activity: {id: null, name: null, code: null}, comments: null, date: null, challenges: [], style: undefined, api_key: undefined, ranking: [], show_timer: false, logo: null, finished: null, language: "Español", organization: null, demo: false}
		},
	},
	persist: {
		// paths: ["events", "teams", "eventSelected", "challengeSelected", "ChallengesEvent"]
	},
	getters: {
		getTeamNSelect: state =>{
			let options = []
			state.teams.forEach((e)=>{
				options.push({
					label:e.name_id + " - " + e.name ,
					value:e.id,
				}
				)
			})
			return options
		},
		getTeamNumberById: state=> (id:number) =>{
			const team = state.teams.find(e=> e.id == id)
			if( team)
			{
				return team.name_id + " - " + team.name
			}
			else
			{
				return ""
			}
			
		},
		getTeambyId: state=> (id:number) =>{
			return state.teams.find(e=> e.id == id)
		},
		getTeamSelectorWithAll:state =>{
			const { t } = i18n.global;
			let options = []
			state.teams.forEach((e)=>{
				options.push({
					"value":e.id,
					"label":e.name_id + " - " + e.name
				})
			})
			options.push({
				"value":0,
				"label":t("general.all")
			})
			return options;
		},
		getTeamsFullName:state =>{
			state.teams.forEach(e=>{
				e.full_name = e.name_id + " - " + e.name
			})
			return state.teams
		},
		getTeamRanking: state => (): any | undefined => {
			const ranking = state.teams.sort((a, b) => b.points - a.points)
			let pos = 1
			let beforePoints = -1
			ranking.forEach(r => {
				if(beforePoints != -1 && beforePoints != r.points)
				{
					pos++
				}
				beforePoints = r.points
				r.position = pos
				
			});
			
			return ranking
		},
		getTeamPosition: state => (id): any | undefined => {
			const ranking = state.teams.sort((a, b) => b.points - a.points)
			let pos = 1
			let beforePoints = -1
			ranking.forEach(r => {
				if(beforePoints != -1 && beforePoints != r.points)
				{
					pos++
				}
				beforePoints = r.points
				r.position = pos
			});
			console.log(ranking);
			const currentTeam = ranking.find(e=> e.id == id);
			if( currentTeam)
			{
				return currentTeam.position
			}
			return 0
		},

	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useEventsStore, import.meta.hot))
}

import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';

export const useNavigationStore = defineStore('navigation', {
  state: () => ({
    navigationHistory: [], // To track navigation history
  }),
  actions: {
    addRoute(route) {
      this.navigationHistory.push(route);
    },
    clearHistory() {
      this.navigationHistory = [];
    },
    goBack(router, previousPage) {
        console.log(previousPage);
        
        if (this.isDirectAccess) {
          router.push(previousPage); // Replace with your actual route
        } else {
          router.go(-1);
        }
      }
  },
  getters: {
    isDirectAccess: (state) => state.navigationHistory.length === 1,
  },
});
<template>
    <span>
        <div class="flex flex-col w-full h-52 items-center justify-center splash-screen-loading-box" v-if="loading">
            <n-spin :size="80" />
        </div>
        <div v-else class="page">
            <n-form
                ref="formRef"
                :model="storeEvents.activityConfiguration"
                :rules="rules"
                :size="size"
                label-placement="top"
            >
                <n-grid :x-gap="24">
                    
                    <n-grid-item :span="6" >
                        <n-grid :x-gap="5" >
                            <n-form-item-gi  :span="12" :label="$t('gm.dashboards.duration_hours')" path="hours">
                                <n-input-number 
                                    v-model:value="storeEvents.activityConfiguration.hours" 
                                    @update:value="recalculateDuration"
                                />
                            </n-form-item-gi>
                            <n-form-item-gi  :span="12" :label="$t('gm.dashboards.duration_minutes')" path="minutes">
                                <n-input-number 
                                    v-model:value="storeEvents.activityConfiguration.minutes"
                                    @update:value="recalculateDuration"
                                />
                            </n-form-item-gi>
                        </n-grid >
                    </n-grid-item>
                  
                    <n-grid-item :span="18" >
                        <n-form-item-gi :label="$t('gm.dashboards.type')" path="settings">
                            <n-select 
                                required
                                v-model:value="storeEvents.activityConfiguration.settings"
                                :placeholder="$t('gm.dashboards.placeholder_type')"
                                :options="settingsOptions"
                                @update:value="setActionSettings"
                            >
                            </n-select>
                        </n-form-item-gi>
                        <n-grid :x-gap="5" >
                            <n-form-item-gi :span="8" :label="$t('gm.dashboards.challenges')" path="challenges" v-if="storeEvents.eventSelected.activity.code == 'AC'">
                                <ul>
                                    <li v-for="item in challengeActionSettings" :key="item">{{ item.name + " - " + item.type }}</li>
                                </ul>
                            </n-form-item-gi>
                            <n-form-item-gi :span="8" label="Tienda" path="Tienda" v-else>
                                <ul>
                                    <li v-for="item in challengeActionSettings" :key="item">{{ item.name}}</li>
                                </ul>
                            </n-form-item-gi>
                            <n-form-item-gi :span="8" :label="$t('gm.dashboards.categories')" path="quiz">
                                <ul>
                                    <li v-for="item in categoryQuizActionSettings" :key="item">{{ item.name }}</li>
                                </ul>
                            </n-form-item-gi>
                        </n-grid >
                    </n-grid-item>
                </n-grid>
            </n-form>
        </div>
    </span>
</template>

<script lang="ts" setup>
import Icon from "@/components/common/Icon.vue"
import { NButton, NForm, NTransfer, NGrid, NFormItemGi, NSelect, useMessage, NSpin, NInputNumber ,NGridItem, NCheckboxGroup, NSpace, NCheckbox} from "naive-ui"
import { computed, onMounted, ref, Ref, nextTick } from "vue"
import { type LabelPlacement, type Size, FormInst } from "naive-ui/es/form/src/interface"
import { useRoute, useRouter } from "vue-router"
import { useEventsStore } from "@/stores/apps/events"
import { axiosToken, fetchPromiseAll } from "@/utils/http";
import type { AxiosResponse } from "axios";
import { useContentStore } from "@/stores/apps/content"
import { useActionStore } from "@/stores/apps/action"
import { useStoreI18n } from "@/composables/useStoreI18n"

const { t } = useStoreI18n()
const storeEvents = useEventsStore()
const storeContent = useContentStore()
const storeAction = useActionStore()

const ArrowLeft = "carbon:arrow-left"
const SaveIcon = "carbon:save"

const route = useRoute()
const router = useRouter()
const size = ref<Size | undefined>("medium")
const categoryQuizActionSettings = ref([]);
const quizActionSettings = ref([]);
const challengeActionSettings = ref([]);

const settings = computed(() => { return storeContent.content.actionChallenge_settings})


let settingsOptions = []
const loading = ref(true)
onMounted( async () => {
    storeEvents.activityConfiguration = {settings: "", duration: 0, hours: 0, minutes: 0, activity_formRef: formRef}
    if(route.params.api_key)
    {
        await fetchPromiseAll([
            storeAction.getActionData(String(route.params.api_key)),
            storeContent.getAllMultipleContent(["actionChallenge_settings","category_quiz", "action_challenge_quiz", "action_challenge_challenge","action_item"]),
        ])
        loadActionForm()
    } else {
        await fetchPromiseAll([
            storeContent.getAllMultipleContent(["actionChallenge_settings","category_quiz", "action_challenge_quiz", "action_challenge_challenge","action_item"]),
        ])
    }
    if( storeEvents.eventSelected.activity.code == 'AC')
    {
        settingsOptions = settings.value.filter(e=> !e.use_store).map(v => ({label: v.name, value: v.id.toString()}))
    }
    else
    {
        settingsOptions = settings.value.filter(e=> e.use_store).map(v => ({label: v.name, value: v.id.toString()}))
    }
    
    loading.value = false
});

const loadActionForm = () => 
{
    storeEvents.activityConfiguration.duration = storeAction.actionData.duration
    storeEvents.activityConfiguration.hours = Math.trunc(storeAction.actionData.duration / 3600)
    storeEvents.activityConfiguration.minutes = Math.trunc((storeAction.actionData.duration / 60)%60)
    if(storeAction.actionData.settings != undefined)
    {
        storeEvents.activityConfiguration.settings = storeAction.actionData.settings.toString()
        setActionSettings();
    }
}

const actionSettingSelected = ref({id: 0, name: "", categories: [], quiz: [], challenge: []})

const setActionSettings = () => 
{
    categoryQuizActionSettings.value = []
    quizActionSettings.value = []
    challengeActionSettings.value = []
    nextTick(() => {
        actionSettingSelected.value = storeContent.content.actionChallenge_settings.find( (v:any) => v.id == parseInt(storeEvents.activityConfiguration.settings))

        console.log(actionSettingSelected.value);
        storeContent.content.category_quiz.forEach(e => {
            if(actionSettingSelected.value.categories.includes(e.id))
            {
                categoryQuizActionSettings.value.push(e)
            }
        });

        storeContent.content.action_challenge_quiz.forEach(e => {
            if(actionSettingSelected.value.quiz.includes(e.id))
            {
                quizActionSettings.value.push(e)
            }
        });
        
        storeContent.content.action_challenge_challenge.forEach(e => {
            if(actionSettingSelected.value.challenges.includes(e.id))
            {
                challengeActionSettings.value.push(e)
            }
        });

        storeContent.content.action_item.forEach(e => {
            if(actionSettingSelected.value.items_store.includes(e.id))
            {
                challengeActionSettings.value.push(e)
            }
        });
    });
}

const formRef = ref<FormInst | null>(null)
const rules = {
            settings: {
                required: true,
                trigger: ['blur', 'change'],
                message: t('gm.dashboards.placeholder_template')
            },
            hours: {
                type: 'number',
                required: true,
                trigger: ['blur', 'change'],
                message: t('gm.dashboards.time_action')
            },
            minutes: {
                type: 'number',
                required: true,
                trigger: ['blur', 'change'],
                message: t('gm.dashboards.time_action')
            },
        };

const recalculateDuration = () => 
{
    storeEvents.activityConfiguration.duration = storeEvents.activityConfiguration.hours * 3600 + storeEvents.activityConfiguration.minutes * 60
}

</script>

<style lang="scss" scoped>
.components-list {
	grid-template-columns: none;
}
.card-css {
    background-color: var(--bg-color);
}
</style>

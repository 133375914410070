<template>
	<div id="main" class="main" :class="{ 'sidebar-collapsed': sidebarCollapsed, 'sidebar-opened': !sidebarCollapsed }">
		<n-scrollbar ref="scrollbar">
			
			<Toolbar :boxed="toolbarBoxed" />
			<div class="view" :class="[{ boxed }, `route-${routeName}`]">
				<!-- <n-button-group>
					<n-button @click="activate('top')">
					Top
					</n-button>
					<n-button @click="activate('right')">
					Right
					</n-button>
					<n-button @click="activate('bottom')">
					Bottom
					</n-button>
					<n-button @click="activate('left')">
					Left
					</n-button>
				</n-button-group>
				<div
					id="drawer-target"
					style="
					position: relative;
					width: 100%;
					height: 300px;
					border: 1px solid rgba(128, 128, 128, 0.2);
					margin-top: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					overflow: hidden;
					"
				>
					Target Area
				</div>
				<n-drawer
					v-model:show="active"
					:width="200"
					:height="200"
					:placement="placement"
					:trap-focus="false"
					to="#drawer-target"
				>
					<n-drawer-content title="Stoner">
					Stoner is a 1965 novel by the American writer John Williams.
					</n-drawer-content>
				</n-drawer> -->
				<n-drawer  v-model:show="storeNotification.showDrawer" :width="400" style="max-width: 90vw" :trap-focus="false" :placement="'left'">
					<n-drawer-content :title="$t('menu.notifications')" closable body-content-style="padding:0">
						<NotificationsList />
						<template #footer>
							<NotificationsToolbar />
						</template>
					</n-drawer-content>
				</n-drawer>
				<slot></slot>
			</div>
			<!-- <FooterEL :boxed="boxed" v-if="footerShown" /> -->
		</n-scrollbar>
	</div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from "vue"
import { NScrollbar, NButton, NDrawer, NDrawerContent  } from "naive-ui"
import { useRoute, useRouter } from "vue-router"
import Toolbar from "@/app-layouts/common/Toolbar/index.vue"
import FooterEL from "@/app-layouts/common/FooterEL.vue"
import { useThemeStore } from "@/stores/theme"
import type { DrawerPlacement} from 'naive-ui'
import NotificationsList from "@/components/common/Notifications/List.vue"
import NotificationsToolbar from "@/components/common/Notifications/Toolbar.vue"
import { useNotificationStore } from "@/stores/apps/notification"

defineOptions({
	name: "MainContainer"
})

// const active = ref(false)
//     const placement = ref<DrawerPlacement>('right')
//     const activate = (place: DrawerPlacement) => {
//       active.value = true
//       placement.value = place
//     }

const storeNotification = useNotificationStore();

const themeStore = useThemeStore()
const router = useRouter()
const route = useRoute()
const routeName = computed<string>(() => route.name?.toString() || "")
const sidebarCollapsed = computed(() => themeStore.sidebar.collapsed)
const boxed = computed(() => themeStore.isBoxed)
const footerShown = computed(() => themeStore.isFooterShown)
const toolbarBoxed = computed(() => themeStore.isToolbarBoxed)
const scrollbar = ref()

onMounted( async () => {
	
	router.afterEach(() => {
		if (scrollbar?.value?.scrollTo) {
			scrollbar?.value.scrollTo({ top: 0 })
		}
	})
})
</script>

<style lang="scss" scoped>
@import "./variables";

.main {
	width: 100%;
	padding-left: var(--sidebar-open-width);
	position: relative;
	transition: padding var(--sidebar-anim-ease) var(--sidebar-anim-duration);
	background-color: var(--bg-body);

	:deep() {
		& > .n-scrollbar {
			& > .n-scrollbar-rail {
				top: calc(var(--toolbar-height) + 2px);
			}
		}
	}
	&.sidebar-collapsed {
		padding-left: var(--sidebar-close-width);
	}

	.view {
		padding: var(--view-padding);
		padding-top: calc(var(--view-padding) / 2);

		&.boxed {
			max-width: var(--boxed-width);
			margin: 0 auto;
		}
	}

	@media (max-width: $sidebar-bp) {
		padding-left: 0px;
		&.sidebar-collapsed {
			padding-left: 0px;
		}

		transition: all var(--sidebar-anim-ease) var(--sidebar-anim-duration);

		&.sidebar-opened {
			//transform: scale(0.8) translateX(100%) rotateY(35deg);
			//transform: scale(0.8) translateX(100%);
			transform-origin: center left;
			border-radius: 16px;
			overflow: hidden;
			opacity: 0.5;
		}
	}

	@media (min-width: ($sidebar-bp + 1px)) {
		:deep() {
			header.toolbar {
				.logo {
					display: none;
				}
			}
		}
	}
}
</style>

import { axiosToken, simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";
import type { List } from "lodash";
import { defineStore, acceptHMRUpdate } from "pinia"
import { useTapasStore } from "./tapas";
import type { TapasChallenge } from "./interfaces/tapas_interfaces";
import { translate, translateAll } from "@/utils/translate";
import { useEventsStore } from "./events";
import { useContentStore } from "./content";
import { useRoute } from "vue-router";
import { useCalendarStore } from "./calendar";
import { useBudgetStore } from "./budget";

interface Booking_Item {
    id:Number,
    name:String,
    description:String,
	price?:String,
	activity:String,

}

export const useBookingStore = defineStore("booking", {
	state: () => ({
		bookings:[],
		booking_items:[] as Booking_Item[],
		booking_questions: [],
		contacts: [],
		booking_info:[],
		booking:{},
		unSavedData:{},
	}),
	actions: {
		async setDefault()
		{
			this.bookings = []
			this.booking_items = [] as Booking_Item[]
			this.booking_questions = []
			this.contacts = []
			this.booking_info = []
			this.booking = {}
			this.unSavedData = {}
		},
		async fetchBookingsBudgets ()
		{
			const calendarStore = useCalendarStore();
			const budgetStore = useBudgetStore();
			await axiosToken("get", "booking/budgets_bookings", {})
			.then((response: AxiosResponse) => {
                console.log(response.data);
                this.bookings = response.data.bookings;
				budgetStore.budgets = response.data.budgets;
				calendarStore.calendarData = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async fetchBookingInfo(id:Number)
		{
			await axiosToken("get","booking/booking_info/" + id).then((response)=>{
				this.booking_info = response.data
			})
		},
		async fetchBookingClient(api_key:String)
		{
			await simpleAxios("get","booking/booking/" + api_key).then((response)=>{
				console.log(response.data);
				
				this.booking = response.data
			})
		},
		async fetchUnSavedData(api_key:String)
		{
			await simpleAxios("get","booking/check_edits/" + api_key).then((response)=>{
				console.log(response.data);
				
				this.unSavedData = response.data
			})
		},
		async fetchBookingItems(id_client:Number)
		{
			await axiosToken("get","booking/get_total_billed/" + id_client).then((response)=>{
				this.booking.total_billed = response.data
				return response.data
			})
		},
		newLine(pax = 0){
			this.booking_info.bookingItem.push({
				id:-1,
				name:"",
				pax:pax,
				activity:"",
			})
		},
	},
	persist: {
		// paths: ["events"]
	},
	getters:{
		getOptionsTags:  state => (activity: number) => {
			// NEED ACTIVITY FROM THE OTHER STORE
			const contentStore = useContentStore();
			const activityData = contentStore.getContentByTypeById("activity", activity)
			const tags = []
			if( Array.isArray(activityData.price_rules))
			{
				activityData.price_rules.forEach(e =>{
					tags.push({
						value:e.name,
						label:e.name
					})
				})

			}
			return tags
		},
		getPricing:state =>(activity: number, pax:number, tag:String)=>{
			// NEED ACTIVITY FROM THE OTHER STORE
			const contentStore = useContentStore();
			const activityData = contentStore.getContentByTypeById("activity", activity)
			let price = 0;

			if(tag == 'Custom')
			{
				return price
			}

			if( Array.isArray(activityData.price_rules))
			{
				console.log("INNNNNN")
				const priceArray = activityData.price_rules.find(e=> e.name == tag)
				for (let index = 0; index < priceArray.list.length; index++) {
					const element = priceArray.list[index];
					console.log(element);
					
					if(pax >=  parseInt(element.pax_low)  && pax <= parseInt(element.pax_high))
					{
						price = element.price_total
						break;
					}
				}
			}
			console.log(pax,price);
			return price; 
		},
		getPaxInterval:state =>(activity: number, tag:String)=>{
			const contentStore = useContentStore();
			const activityData = contentStore.getContentByTypeById("activity", activity)
			const tagPax = []

			if(tag == 'Custom')
			{
				return ''
			}

			if( Array.isArray(activityData.price_rules))
			{
				const priceArray = activityData.price_rules.find(e=> e.name == tag)
				for (let index = 0; index < priceArray.list.length; index++) {
					const element = priceArray.list[index];
					tagPax.push({
						"label": element.pax_low + ' - ' + element.pax_high,
						"value": index,
					})
				}
			}
			return tagPax
		},
		getPriceByIndex:state =>(activity: number, index:number, tag:String)=>{
			const contentStore = useContentStore();
			const activityData = contentStore.getContentByTypeById("activity", activity)
			let price = 0;
			if( Array.isArray(activityData.price_rules))
			{
				const priceArray = activityData.price_rules.find(e=> e.name == tag)
				price = priceArray.list[index].price_total
			}
			console.log(price);
			return price; 
		}
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useBookingStore, import.meta.hot))
}

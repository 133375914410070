var ws_type = location.protocol == "https:" ? "wss://" : "ws://";
console.log(ws_type);
import { useIpadHuntStore } from "@/stores/apps/ipad_hunt";

const ip_port = import.meta.env.VITE_APP_IP_PORT;

const startSocket = (url:string, onMsgCallback:(arg:string)=>void) => {
    let reconect = 0;
    let socketEvent = new WebSocket(ws_type + ip_port + "ws/" + url);
    console.log(url);
    if( url.includes("IpadHunt/"))
    {
        const iapdStore = useIpadHuntStore();
        iapdStore.ws = socketEvent;
    }
    socketEvent.onopen = function (e) {
        console.log("[open] Conexión establecida");
    };

    socketEvent.onclose = function (event) {
        setTimeout(function () {
            startSocket(url, onMsgCallback);
            if (reconect == 0) {
            }
            reconect++;
            if (reconect == 9) {
                reconect = 0;
            }
        }, 1000);
    };

    socketEvent.onmessage = function (event) {
        const dataWs = JSON.parse(event.data);
        //console.log(event.data);
        onMsgCallback(event.data);
    };

    socketEvent.onerror = function (error) {
        console.log(`[error]`);
    };

    return socketEvent;
};



export { startSocket };

<template>
	<div
		class="notifications-toolbar flex"
		:class="{ 'justify-between': hasNotifications, 'justify-end': !hasNotifications }"
	>
		<!-- <n-button quaternary @click="deleteAll()" v-if="hasNotifications">Clear</n-button> -->
		<n-button strong secondary type="primary" :disabled="!storeNotification.getUnreadNotifications()" @click="setAllRead()">
			{{$t('general.mark_all_read')}}
		</n-button>
	</div>
</template>

<script lang="ts" setup>
import { NButton } from "naive-ui"
import { useNotifications } from "@/composables/useNotifications"
import { useNotificationStore } from "@/stores/apps/notification";
import { axiosToken } from "@/utils/http";

const storeNotification = useNotificationStore()
// const hasUnread = useNotifications().hasUnread
const hasNotifications = useNotifications().hasNotifications

async function setAllRead() {
	await axiosToken("post","notifications/read_all/").then((response)=>{
		console.log(response);
	})
	storeNotification.setAllNotifications()
	// useNotifications().setAllRead()
}

function deleteAll() {
	useNotifications().deleteAll()
}
</script>

<style>
.notifications-toolbar {
	width: 100%;
}
</style>

<template>
    <span>
        <div class="flex flex-col w-full h-52 items-center justify-center splash-screen-loading-box" v-if="loading">
            <n-spin :size="80" />
        </div>
        <div v-else class="page">
            <n-form
                ref="formRef"
                :model="storeEvents.activityConfiguration"
                :rules="rules"
                :size="size"
                label-placement="top"
            >
                <n-grid :x-gap="24">
                    
                    <n-grid-item :span="12" >
                        <n-form-item-gi :span="12" :label="$t('gm.dashboards.challenges')" path="challenges">
                            <n-transfer
                                v-model:value="storeEvents.activityConfiguration.challenges"
                                style="width: 100%"
                                :options="challengesOptions"
                            />
                        </n-form-item-gi>
                        <n-form-item-gi :span="12" :label="$t('gm.dashboards.score_categories')" path="challenges">
                           
                                <n-space item-style="display: flex;">
                                    <n-checkbox v-model:checked="storeTapas.tapasData.ranking_defense" :label="$t('gm.dashboards.defense')" />
                                    <n-checkbox v-model:checked="storeTapas.tapasData.ranking_presentation" :label="$t('gm.dashboards.presentation')" />
                                    <n-checkbox v-model:checked="storeTapas.tapasData.ranking_savour" :label="$t('gm.dashboards.flavor')" />
                                    <n-checkbox v-model:checked="storeTapas.tapasData.ranking_money" :label="$t('gm.dashboards.money')" />
                                </n-space>
                            
                        </n-form-item-gi>
                    </n-grid-item>
                    <n-grid-item :span="12" >
                        <n-grid :x-gap="5" >
                            <n-form-item-gi :span="12" :label="$t('gm.dashboards.round_time')" path="round_duration_challange">
                                <n-input-number v-model:value="storeEvents.activityConfiguration.round_duration_challange" />
                            </n-form-item-gi>
                            <n-form-item-gi  :span="12" :label="$t('gm.dashboards.tapas_time')" path="duration_tapas">
                                <n-input-number v-model:value="storeEvents.activityConfiguration.duration_tapas" />
                            </n-form-item-gi>
                        </n-grid >
                        <n-form-item-gi :label="$t('gm.dashboards.type')" path="tapas_settings">
                            <n-select 
                                required
                                v-model:value="storeEvents.activityConfiguration.tapas_settings"
                                :placeholder="$t('gm.dashboards.placeholder_type')"
                                :options="tapasSettingsOptions"
                                @update:value="setTapasSettings"
                            >
                            </n-select>
                        </n-form-item-gi>
                        <n-grid :x-gap="5" >
                            <n-form-item-gi :span="12" :label="$t('gm.dashboards.ingredient')" path="ingredients">
                                <ul>
                                    <li v-for="item in ingredientsTapasSettings" :key="item">{{ item.name + " - " + item.tpye }}</li>
                                </ul>
                            </n-form-item-gi>
                            <n-form-item-gi :span="12" :label="$t('gm.dashboards.shop')" path="cards">
                                <ul>
                                    <li v-for="item in cardsTapasSettings" :key="item">{{ item.name }}</li>
                                </ul>
                            </n-form-item-gi>
                        </n-grid >
                    </n-grid-item>
                </n-grid>
            </n-form>
        </div>
    </span>
</template>

<script lang="ts" setup>
import Icon from "@/components/common/Icon.vue"
import { NButton, NForm, NTransfer, NGrid, NFormItemGi, NSelect, useMessage, NSpin, NInputNumber ,NGridItem, NCheckboxGroup, NSpace, NCheckbox} from "naive-ui"
import { computed, onMounted, ref, Ref, nextTick } from "vue"
import { type LabelPlacement, type Size, FormInst } from "naive-ui/es/form/src/interface"
import { useRoute, useRouter } from "vue-router"
import { useEventsStore } from "@/stores/apps/events"
import { axiosToken, fetchPromiseAll } from "@/utils/http";
import type { AxiosResponse } from "axios";
import { useContentStore } from "@/stores/apps/content"
import { useTapasStore } from "@/stores/apps/tapas"
import { useStoreI18n } from "@/composables/useStoreI18n"

const { t } = useStoreI18n()
const storeEvents = useEventsStore()
const storeContent = useContentStore()
const storeTapas = useTapasStore()

const ArrowLeft = "carbon:arrow-left"
const SaveIcon = "carbon:save"

const route = useRoute()
const router = useRouter()
const size = ref<Size | undefined>("medium")
const challengesSelected = computed(() => { return storeTapas.tapasData.challenges})
const cardsTapasSettings = ref([]);
const ingredientsTapasSettings = ref([]);

const challenges = computed(() => { return storeContent.content.challenge})
const tapasSettings = computed(() => { return storeContent.content.tapas_settings})


let challengesOptions = []
let tapasSettingsOptions = []
const loading = ref(true)
const formRef = ref<FormInst | null>(null)
onMounted( async () => {
    storeEvents.activityConfiguration = {challenges: [], round_duration_challange: 0, duration_tapas: 0, 
                                            categories: {ranking_money: categories.value[0] ? true : false, 
                                            ranking_defense: categories.value[1] ? true : false, 
                                            ranking_presentation: categories.value[2]  ? true : false, 
                                            ranking_savour: categories.value[3]  ? true : false}, 
                                            tapas_settings: null,
                                            activity_formRef: formRef
                                        }
    if(route.params.api_key)
    {
        await fetchPromiseAll([
            storeEvents.getEventFull(String(route.params.api_key)),
            storeContent.getAllMultipleContent(["tapas_settings", "item_shop", "ingredient","challenge"]),
            storeTapas.getTapasData(String(route.params.api_key)),
        ])
        loadTapasForm()
    }
    else
    {
        await fetchPromiseAll([
            storeContent.getAllMultipleContent(["tapas_settings", "item_shop", "ingredient","challenge"]),
        ])
    }
    challengesOptions = challenges.value.map(v => ({label: v.name, value: v.id}))
    
    tapasSettingsOptions = tapasSettings.value.map(v => ({label: v.name, value: v.id.toString()}))
    
    loading.value = false
});

const loadTapasForm = () => 
{
    storeEvents.activityConfiguration.duration_tapas = storeTapas.tapasData.duration_tapas
    storeEvents.activityConfiguration.round_duration_challange = storeTapas.tapasData.round_duration_challange
    if(storeTapas.tapasData.tapas_settings != undefined)
    {
        storeEvents.activityConfiguration.challenges = challengesSelected.value.map(v => v.id)
        storeEvents.activityConfiguration.tapas_settings = storeTapas.tapasData.tapas_settings.id.toString()
        setTapasSettings();
    }
}

const categories = ref([true, true, true, true])

const setTapasSettings = () => 
{
    ingredientsTapasSettings.value = []
    cardsTapasSettings.value = []
    nextTick(() => {
        storeEvents.activityConfigurationtapas_settings = storeContent.content.tapas_settings.find( (v:any) => v.id == parseInt(storeEvents.activityConfiguration.tapas_settings))
        storeContent.content.item_shop.forEach(e => {
            if(storeEvents.activityConfigurationtapas_settings.item_shop.includes(e.id))
            {
                cardsTapasSettings.value.push(e)
            }
        });

        storeContent.content.ingredient.forEach(e => {
            if(storeEvents.activityConfigurationtapas_settings.ingredients.includes(e.id))
            {
                ingredientsTapasSettings.value.push(e)
            }
        });
        
    });
}

const message = useMessage()
const rules = {
            challenges: {
                type: 'array',
                required: true,
                trigger: 'change',
                message: t('gm.dashboards.select_challenge')
            },
            tapas_settings: {
                required: true,
                trigger: ['blur', 'change'],
                message: t('gm.dashboards.select_activity')
            },
            round_duration_challange: {
                type: 'number',
                required: true,
                trigger: ['blur', 'change'],
                message: t('gm.dashboards.select_time_round')
            },
            duration_tapas: {
                type: 'number',
                required: true,
                trigger: ['blur', 'change'],
                message: t('gm.dashboards.select_time_tapas')
            },
            categories:{
                type: 'array',
                required: true,
                trigger: 'change',
                message: t('gm.dashboards.select_categories')
            }
        };


</script>

<style lang="scss" scoped>
.components-list {
	grid-template-columns: none;
}
.card-css {
    background-color: var(--bg-color);
}
</style>

<template>
	<div class="page">
        <div class="flex flex-col w-full h-52 items-center justify-center splash-screen-loading-box" v-if="loading">
            <n-spin :size="80" />
        </div>
        <span v-else>
            <div class="card-css page-header rounded-md py-6 px-3">
                <div class="flex flex-row justify-between gap-4 items-center w-full">
                    <h1>{{$t('activities.activity')}}</h1>
                    <div class="flex flex-row gap-2">
                        <n-button v-if="$route.params.id" @click="goDashboard" type="info" ghost>{{$t('activities.dashboard')}} &nbsp;<Icon :size="20" :name="LinkIcon"></Icon></n-button>
                        <n-button v-if="$route.params.id" @click="showModalRef = true" type="error" ghost>{{$t('general.delete')}} &nbsp;<Icon :size="20" :name="TrashIcon"></Icon></n-button>
                        <n-button @click="validateForms" :loading="loadingBtnSave" type="primary" ghost>{{$t('general.save')}} &nbsp;<Icon :size="20" :name="SaveIcon"></Icon></n-button>
                    </div>
                </div>
            </div>
            <n-form
                ref="formRefEvent"
                :model="event"
                :rules="rules"
                :size="size"
                label-placement="top"
                v-if="current === 1"
            >
                <n-grid :span="24" :x-gap="24">
                    <n-form-item-gi :span="7" :label="$t('activities.name_activity')" path="name">
                        <n-input v-model:value="event.name" 
                            :placeholder="$t('activities.tl_activity') + '...'" />
                    </n-form-item-gi>
                    <n-form-item-gi :span="5" :label="$t('activities.date_hour')" path="date">
                        <n-date-picker v-model:value="event.date" type="datetime" />
                    </n-form-item-gi>
                    <n-form-item-gi :span="7" :label="$t('activities.activity')" path="activity">
                        <n-select 
                            required
                            v-model:value="event.activity"
                            :placeholder="$t('activities.select_activity')"
                            :options="activityOptions"
                            :disabled="$route.params.api_key !== undefined"
                        >
                        </n-select>
                    </n-form-item-gi>
                    <n-form-item-gi :span="5" :label="$t('activities.language')" path="language">
                        <n-select 
                            required
                            v-model:value="event.language"
                            :placeholder="$t('activities.select_language')"
                            :options="languageOptions"
                        >
                        </n-select>
                    </n-form-item-gi>
                    <n-form-item-gi :span="16" :label="$t('activities.comments')" path="comments">
                        <n-input
                            v-model:value="event.comments"
                            :placeholder="$t('activities.comments') + '...'"
                            type="textarea"
                            :autosize="{
                                minRows: 4,
                                maxRows: 5
                            }"
                        />
                    </n-form-item-gi>
                    <n-form-item-gi :label="$t('activities.logo')" path="clientLogoValue" :span="4">
                        <n-upload
                            @change="handleFileChange"
                            :default-file-list="logo"
                            list-type="image-card"
                            :max="1"
                        >
                            {{$t('activities.click_add')}}
                        </n-upload>
                    </n-form-item-gi>
                    <n-form-item-gi label="" :span="4">
                        <n-checkbox v-model:checked="event.demo">
                            {{$t('activities.demo')}}
                        </n-checkbox>
                    </n-form-item-gi>
                </n-grid>
            </n-form>

            <n-modal
                v-model:show="showModalRef"
                :mask-closable="false"
                preset="dialog"
                :title="$t('activities.delete') +': ' + event.name " 
                :content="$t('activities.sure')"
                positive-text="Aceptar"
                negative-text="Cancelar"
                @positive-click="deleteEvent"
                @negative-click="onNegativeClick"
            />
        </span>
        <ActivityConfiguration />
	</div>
</template>

<script lang="ts" setup>
import Icon from "@/components/common/Icon.vue"
import { NButton, NForm, NUpload, NInput, NDatePicker, type UploadFileInfo, NGrid, NFormItemGi, NSelect, useMessage, NModal, StepsProps, NSpin, NCheckbox } from "naive-ui"
import { computed, onMounted, ref, Ref, watch } from "vue"
import { type LabelPlacement, type Size, FormInst } from "naive-ui/es/form/src/interface"
import { log } from "console"
import { useRoute, useRouter } from "vue-router"
import { useEventsStore } from "@/stores/apps/events"
import type {  FileInfo } from "naive-ui/es/upload/src/interface"
import { axiosToken } from "@/utils/http";
import type { AxiosResponse } from "axios";
import { useContentStore } from "@/stores/apps/content"
import { useStoreI18n } from "@/composables/useStoreI18n"
import ActivityConfiguration from "./ActivityConfiguration.vue"
import { cleanStore } from "@/utils/general"
import dayjs from "@/utils/dayjs"

const showModalRef = ref(false)
const TrashIcon = "carbon:trash-can"
const SaveIcon = "carbon:save"
const LinkIcon = "carbon:launch"
const ArrowRight = "carbon:arrow-right"
const ArrowLeft = "carbon:arrow-left"
const storeEvents = useEventsStore()
const route = useRoute()
const router = useRouter()
const size = ref<Size | undefined>("medium")
const activities = computed(() => { return storeContent.activities})
const activityOptions = ref([]);
const { t } = useStoreI18n()
const languageOptions = ref([{label: t('general.es'), value: "es"}, {label: t('general.en'), value: "en"}]);
const logo = ref<any>([])
const loading = ref(true)
const storeContent = useContentStore()
const booking = ref(null)
const loadingBtnSave = ref(false)
onMounted( async () => {
    await cleanStore()
    await storeContent.getActivities()
    let logoLoaded:File | Array<File> = []
    if(route.params.api_key != undefined)
    {
        await storeEvents.getEvent(String(route.params.api_key))
        var eventData = storeEvents.eventSelected;
        console.log(eventData.logo);
        if(eventData.logo != undefined)
        {
            logoLoaded = await fetchLogo(eventData.logo)   
        }
        console.log({...eventData});
        
        event.value = {id: eventData.id, name: eventData.name, date: new Date(eventData.date).getTime(), comments: eventData.comments, 
                        challenges: eventData.challenges, activity: eventData.activity.id.toString(), logo: logoLoaded, language: eventData.language, demo: eventData.demo }
    }
    
    if('booking' in route.query)
    {
        booking.value = await storeContent.fetchGatewayFind('booking', route.query.booking, 'id');
        booking.value = booking.value[0]

        event.value.name = booking.value.name
        
        if(booking.value.time)
        {
            event.value.date = dayjs(booking.value.date + ' ' + booking.value.time).valueOf();
        }
        else
        {
            event.value.date = dayjs(`${booking.value.date} 00:00:00`).valueOf();
        }

        if(booking.value.language == "cat")
        {
            event.value.language = "es"
        }
        else
        {
            event.value.language = booking.value.language
        }
        
        if(booking.value.logo_client)
        {
            event.value.logo = await fetchLogo(booking.value.logo_client)
        }
    }

    activityOptions.value = activities.value.map(v => ({label: v.name, value: v.id.toString()}))
    if(route.query.demo && route.params.api_key == undefined )
    {
        event.value.demo = true
    }
    loading.value = false
});

const onNegativeClick = () => 
{
    message.success(t('general.canceled'))
    showModalRef.value = false
}

const fetchLogo = async (logoUrl:string | URL | Request) =>
{
    let logoLoaded:File | Array<File> = []

    await fetch(logoUrl)
            .then((res) => res.blob())
            .then((myBlob) => {
                const filenameWithQueryParams = logoUrl.split('/').pop(); // Get the last segment of the URL
                const filename = filenameWithQueryParams.split('?')[0];
                logoLoaded = new File([myBlob], filename, {type: myBlob.type});
                logo.value.push({id: logoLoaded.name, name: logoLoaded.name, url: logoUrl, status: 'finished'})
            });
            
    return logoLoaded
}

const handleFileChange = (newFile:any) => {
      event.value.logo = newFile.file.file;
    };

const event_api_key = ref<string>("")

const deleteEvent = async () =>
{
    await axiosToken("delete", "events/event/" + route.params.api_key, {})
    .then( async (response: AxiosResponse) => {
        if(response.status == 202)
        {
            storeEvents.clearEventSelected()
            message.success(t('activities.delete_ok'));
            await storeEvents.getEvents()
            router.push("/events_list")
        }
        else
        {
            message.error(t('activities.delete_error'))
        }
    });
    showModalRef.value = false
} 

const saveEvent = async () => {
    loadingBtnSave.value = true
    var language = event.value.language;
    var type_request = route.params.api_key != undefined ? "put" : "post"
    var api_key = route.params.api_key != undefined ? route.params.api_key : "";
    var message_request = type_request == "put" ? " " + t('general.updated') + " " : " " + t('general.created') + " "
    var message_request_negative = type_request == "put" ? " " + t('general.update') + " " : " " + t('general.create') + " "
    var formData = new FormData();

    const date = new Date(event.value.date);
    
    formData.append("name", event.value.name);
    formData.append("date", date.toISOString());
    formData.append("activity", parseInt(event.value.activity));
    formData.append("comments", event.value.comments);
    formData.append("language", language);
    
    console.log(event.value.logo);
    
    if(event.value.logo?.length > 0  )
    {
        const name = event.value.logo.name + "." + event.value.logo.type.substring(6);
        formData.append("logo", event.value.logo,name );
    }
    
    formData.append("demo", event.value.demo ? "true" : "false");
    
    if('booking' in route.query)
    {
        formData.append("booking", booking.value.id);
    }
    
    await axiosToken(type_request, "events/event/" + api_key, formData, {"Content-Type": "multipart/form-data"},)
    .then( async (response: AxiosResponse) => {
        if(response.data.api_key != undefined)
        {
            api_key = response.data.api_key
            await axiosToken('put', "events/event_full/" + api_key, storeEvents.activityConfiguration)
            .then( async (response: AxiosResponse) => {
                if(response.data.id != undefined)
                {
                    message.success(t('activities.save_ok', {message_request: message_request}))
                    router.push("/events/dashboard/" + api_key)
                }
                else
                {
                    message.error(t('activities.save_ok', {message_request_negative: message_request_negative}))
                }
            })
            .catch((e: any) => {
                console.log(e);
                loadingBtnSave.value = false
            });
        }
        else
        {
            message.error(t('activities.save_ok', {message_request_negative: message_request_negative}))
        }
    })
    .catch((e: any) => {
        console.log(e);
        loadingBtnSave.value = false
    });
}
const formRef = ref<FormInst | null>(null)
const formRefEvent = ref<FormInst | null>(null)
const eventActivity: Ref<any> = ref({});
const formActivityRef = ref(null);
const message = useMessage()

const validateForms = async (e: MouseEvent) =>
{
    e.preventDefault()
    formRefEvent.value?.validate((errors) => {
        if (!errors) 
        {
            storeEvents.activityConfiguration.activity_formRef?.validate((errors) => {
                if (!errors) {
                    storeEvents.activityConfiguration.activity_formRef = null
                    saveEvent()
                } else {
                    console.log(errors)
                    message.error(t('general.incorrect_fields'))
                }
            })
        } else {
        console.log(errors)
        message.error(t('general.incorrect_fields'))
        }
    })
}

const eventStyle = ref({primary_color: "#05C68B", secondary_color: "#000000", logo: null})

const styleRules = {
    primary_color: {
        trigger: ['change'],
        message: t('activities.select_primary_color')
    },
    secondary_color: {
        trigger: ['change'],
        message: t('activities.select_secondary_color')
    },
}

const rules = {
            name: {
                required: true,
                trigger: ['blur'],
                message: t('activities.alert_name_activity')
            },
            activity: {
                required: true,
                trigger: ['blur', 'change'],
                message: t('activities.select_activity')
            },
            date: {
                type: 'number',
                required: true,
                trigger: ['blur', 'change'],
                message: t('activities.select_date')
            },
            challenges: {
                type: 'array',
                required: true,
                trigger: 'change',
                message: t('activities.select_challenge')
            },
            language: {
                trigger: ['blur', 'change'],
                message: t('activities.select_language')
            },
            textareaValue: {
                required: false,
                trigger: ['blur', 'input'],
                message: ''
            },
        };

const event = ref<Event>({
    id: 0, 
    name: "", 
    activity: null, 
    date: new Date().getTime(),
    challenges: [],
    language: "es",
    comments: "",
    logo: []
}) 
type Event = {
    id: number
    name: string | null
    activity: number | null
    language: string
    date: Date | number | null
    challenges: Array<Object> | null
    comments: string | null
    logo: Array<Object> | null
}

const goDashboard = () => 
{
    router.push("/events/dashboard/" + storeEvents.eventSelected.api_key);
}

const currentRef = ref(1)
const currentStatusRef = ref<StepsProps['status']>('process')

const buttonTypeRef = computed(() => {
    switch (currentStatusRef.value) {
    case 'error':
        return 'error'
    case 'finish':
        return 'success'
    default:
        return 'default'
    }
})
const current = currentRef
const currentStatus = currentStatusRef
const handleButtonClick = (direction:string) => {
        if(direction == "next")
        {
            currentRef.value++; 
        }
        else
        {
            currentRef.value--;
        }
    }
const buttonType = buttonTypeRef


watch( () => event.value.activity, () => {
    if(event.value.activity != null)
    {
        storeEvents.eventSelected.activity = storeContent.activities.find(e => e.id == parseInt(event.value.activity))
    }
})

</script>

<style lang="scss" scoped>
.components-list {
	grid-template-columns: none;
}
.card-css {
    background-color: var(--bg-color);
}
</style>

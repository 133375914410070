import axios from "axios";
import type { AxiosResponse } from "axios";

const API_URL = import.meta.env.VITE_APP_API_URL;

const axiosToken = (method: string, url: string, body: object = {}, headers_:String = ""): any => {

    let headers = { authorization: "Token " + localStorage.user }
    headers = { ...headers, ...headers_ }

    try {
        if (method == "delete") {
            return axios.delete(API_URL + url, {
                headers: headers,
                data: body,
            });
        } else if (method == "get") {
            return axios.get(API_URL + url, {
                headers: headers,
                params: body,
            });
        }
        return axios({
            method: method,
            url: API_URL + url,
            headers: headers,
            data: body,
        });
    } catch (error) {
        console.log(error);
        return undefined;
    }
};

const simpleAxios = (method: string, url: string, body: object = {} , headers_:String = ""): any => {
    let headers = {}
    headers = { ...headers, ...headers_ }
    
    if (method == "delete") {
        return axios.delete(API_URL + url, {
            headers:headers,
            data: body,
        });
    } else if (method == "get") {
        return axios.get(API_URL + url, {
            headers:headers,
            params: body,
        });
    }
    return axios({
        method: method,
        url: API_URL + url,
        headers:headers,
        data: body,
    });
  
};

const fetchPromiseAll = async(actions:Array<Promise<void>>) =>
{
    await Promise.all(actions);
}

const fetchFile = async (fileUrl:string | URL | Request) =>
{
    let fileLoaded:File | Array<File> = []
    let result = {}
    if(fileUrl != null)
    {
        await fetch(fileUrl)
            .then((res) => res.blob())
            .then((myBlob) => {
                const filenameWithQueryParams = fileUrl.split('/').pop(); // Get the last segment of the URL
                const filename = filenameWithQueryParams.split('?')[0];
                fileLoaded = new File([myBlob], filename, {type: myBlob.type});
                result = {fileloaded: fileLoaded, id:fileLoaded.name, name: fileLoaded.name, url: fileUrl, status: 'finished'}
            });
    }
    return result
}

const post_gateway = async (data:any, type:string, headers:String = "") =>
{
    let responseReturn = null
    await axiosToken("post", "content/gateway/" + type + "/", data, headers)
    .then((response: AxiosResponse) => {
        console.log(response);
        responseReturn = response
    })
    .catch((e: any) => {
        console.log(e);
        responseReturn = e
    });
    return responseReturn
} 

const put_gateway = async (data:any, type:string, id:string, headers:String = "") =>
{
    let responseReturn = null
    await axiosToken("put", "content/gateway/" + type + "/" + id, data, headers)
    .then((response: AxiosResponse) => {
        console.log(response);
        responseReturn = response
    })
    .catch((e: any) => {
        console.log(e);
        responseReturn = e
    });
    return responseReturn
} 

const delete_gateway = async (type:string, id:number) =>
{
    let responseReturn = null
    await axiosToken("delete", "content/gateway/" + type + "/" + id )
    .then((response: AxiosResponse) => {
        console.log(response);
        responseReturn = response
    })
    .catch((e: any) => {
        console.log(e);
        responseReturn = e
    });
    return responseReturn
} 

const duplicate_gateway = async (type:string, id: number) =>
{
    let responseReturn = null
    await axiosToken("post", "content/gateway/duplicate/" + type + "/" + id)
    .then((response: AxiosResponse) => {
        console.log(response);
        responseReturn = response
    })
    .catch((e: any) => {
        console.log(e);
        responseReturn = e
    });
    return responseReturn
} 

const downloadFile = (url, filename)=> {
    fetch(url).then(function(t) {
        return t.blob().then((b)=>{
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        }
        );
    });
    }

export { axiosToken, simpleAxios, fetchPromiseAll, fetchFile, post_gateway, put_gateway, delete_gateway, duplicate_gateway,downloadFile };

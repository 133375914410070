import { useBookingStore } from "@/stores/apps/booking";
import { useBudgetStore } from "@/stores/apps/budget";
import { useContentStore } from "@/stores/apps/content";
import { useEventsStore } from "@/stores/apps/events";
import { useIpadHuntStore } from "@/stores/apps/ipad_hunt";

const money_calculation = (countQuiz, countCorrects, maxMoney) =>
{
    console.log(countQuiz);
    console.log(countCorrects);
    console.log(maxMoney);
    
    const percentageSuccesses = countCorrects / countQuiz    
    return Math.round(percentageSuccesses * maxMoney)
}

const cleanStore = async () => {
    const contentStore = useContentStore()
    contentStore.setDefault()
    const bookingStore = useBookingStore()
    bookingStore.setDefault()
    const budgetStore = useBudgetStore()
    budgetStore.setDefault()
    const eventStore = useEventsStore()
    eventStore.setDefault()
    const ipadStore = useIpadHuntStore()
    ipadStore.setDefault()
}

const isValidUrl = (url: string): boolean => {
    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
};

export { money_calculation, cleanStore, isValidUrl }
import { startSocket } from "@/utils/ws"
import { useNotificationStore } from "@/stores/apps/notification";

const NotificationWs = () => {
    const notificationStore = useNotificationStore();
    const updateSocket = async (data: string) => {
        const jsonData = JSON.parse(data)
        console.log(jsonData);
        
        if (jsonData.eventType == "new_notification") {
            notificationStore.notifications.push(jsonData.data)
        }
    }
    startSocket('notifications?token=' + localStorage.user, updateSocket);
}


export { NotificationWs };
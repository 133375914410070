import { useLocalesStore } from "@/stores/i18n"

const getTranslatedObject = (object:object, name_var:string) => {
    const localeStore = useLocalesStore()
    const var_name = name_var + '_' + localeStore.locale
    return object[var_name]
}
const translate = (object:object, name_vars:string[]) => {
    const localeStore = useLocalesStore()
    name_vars.forEach(nv => {
        const var_name = nv + '_' + localeStore.locale;
        object[nv] = object[var_name];
    });

    return object
}

const translateAll = (object:object[], name_vars:string[]) => {    
    object.forEach(o => {
        translate(o, name_vars)
    });

    return object
}

const translateSingleString = (object:object, name_var:string) => {
    const localeStore = useLocalesStore()
    const var_name = name_var + '_' + localeStore.locale    
    object[name_var] = object[var_name];

    return object[name_var]
}

export { translate, translateAll, translateSingleString, getTranslatedObject };
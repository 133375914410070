<template>
    <div class="splash-screen-loading-box flex flex-col mt-96 justify-center items-center" v-if="!loaded">
        <n-spin :size="80" /> 
    </div>
    <div v-else>
        <n-data-table :columns="columns" :data="dataShow" :pagination="pagination" :bordered="false" ref="events_table" />
        <n-modal
            v-model:show="showModalRef"
            :mask-closable="false"
            preset="dialog"
            :title="$t('events.delete_event') + ': ' + rowSelected.name " 
            :content="$t('events.are_you_sure')"
            :positive-text="$t('events.accept')"
            :negative-text="$t('events.cancel')"
            @positive-click="deleteEvent"
            @negative-click="onNegativeClick"
        />
    </div>
</template>
<script lang="ts" setup>
import { h, defineComponent, ref, onMounted, reactive } from "vue"
import { NButton, useMessage, type DataTableColumns, NDataTable, NIcon, NSpin, NTime, NModal, NCheckbox, DataTableBaseColumn, NSpace } from "naive-ui"
import { renderIcon } from "@/utils"
import router from "@/router"
import { useEventsStore } from "@/stores/apps/events"
import { log } from "util"
import { axiosToken } from "@/utils/http";
import type { AxiosResponse } from "axios";
import { useRoute } from "vue-router"
import axios from "axios";
import { useStoreI18n } from "@/composables/useStoreI18n";

const { t } = useStoreI18n()
const props = defineProps({
    demo: {
        type: Boolean,
        default: false
    },
})

const route = useRoute()

type Event = {
    id: number
    name: string
    api_key: string
    activity: {code: string, name: string, id: number}
    activity_name: string
    date: Date
    comments: string
    logo: string | null,
    finished: boolean,
    demo: boolean
}

const createColumns = ({ play }: { play: (row: Event) => void }): DataTableColumns<Event> => {
return [
    {
        title: "#",
        key: "id",
        defaultSortOrder: "descend",
        sorter: (row1, row2) => row1.id - row2.id
    },
    {
        title: t('events.event'),
        key: "name"
    },
    {
        title: t('events.game'),
        key: "activity_name"
    },
    {
        title: t('events.day'),
        key: "day",
        render(row) {
            return h(NTime,
                    {
                        strong: true,
                        quaternary: true,
                        size: "small",
                        type: "date",
                        time: row.date,
                        onClick: () => console.log("Edit")
                    },
                    { default: renderIcon("ion:open-outline") }
            )
        }
    },
    {
        title: t('events.actions'),
        key: "actions",
        render(row) {
            return h(
                    'div',
                    [
                    h(NButton,
                        {
                            strong: true,
                            quaternary: true,
                            size: "small",
                            type: "info",
                            onClick: () => {editEvent(row)}
                        },
                        { default: renderIcon("ion:edit") }
                    ),
                    h(NButton,
                        {
                            strong: true,
                            quaternary: true,
                            size: "small",
                            type: "info",
                            loading:loadingDownload.value,
                            onClick: () => {downloadContent(row)}
                        },
                        { default: renderIcon("ion:cloud-download-outline") }
                    ),
                    h(NButton,
                        {
                            strong: true,
                            quaternary: true,
                            size: "small",
                            type: "error",
                            onClick: () => { showModalRef.value = true; rowSelected.value = row}
                        },
                        { default: renderIcon("ion:trash") }
                    ),
                    ]
                )
        }
    },
    {
        title: "",
        key: "ver evento",
        render: (row) => {
            return h(
                        NButton,
                        {
                            type: "success",
                            size: 'small',
                            onClick: () => {goDashboard(row)}
                        },
                        { default: () => t('events.enter') }
                    )
        }
    },
]
}

const data = ref<Event[]>([]);
const loadingDownload = ref(false)
const goDashboard = (row:Event) => 
{
    router.push("/events/dashboard/" + row.api_key);
}

const downloadContent = async(row:Event) => 
{   
    const API_URL = import.meta.env.VITE_APP_API_URL;
    loadingDownload.value = true
    let headers = { authorization: "Token " + localStorage.user }
    await axios({
        "method":'GET',
        "url":(API_URL + "events/download_images/" + row.api_key),
        responseType: 'blob',
        headers,
    })
    .then( async (response: AxiosResponse) => {
        if(response.status == 200)
        {
            console.log(response.data);
            
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            const name = row.name + ".zip"
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        else
        {
            message.error("No hay contenido")
        }
    });
    loadingDownload.value = false
}

const editEvent = (row:Event) => 
{
    router.push("/events/" + row.api_key);
}

const rowSelected = ref<Event>({id: 0, name: "", api_key: "", activity: {code: "", name: "", id: ""}, activity_name: "", date: new Date(), comments: "", logo: null})
const showModalRef = ref(false)
const showModalEnd = ref(false)
const onNegativeClick = () => 
{
    message.warning(t('messages.cancelled'))
    showModalRef.value = false
}

const storeEvents = useEventsStore()
const message = useMessage()
const deleteEvent = async () => 
{
    await axiosToken("delete", "events/event/" + rowSelected.value.api_key, {})
    .then( async (response: AxiosResponse) => {
        if(response.status == 202)
        {
            storeEvents.clearEventSelected()
            message.success(t('messages.event_deleted'));
            await storeEvents.getEvents()
            const index:number = data.value.indexOf(rowSelected.value);
            data.value.splice(index, 1);
        }
        else
        {
            message.error(t('messages.error_deleting_event'))
        }
    });
}

const changeStateEvent = async () => {
    axiosToken("put","events/event_state/" + rowSelected.value.api_key, {
        finished: !rowSelected.value.finished
    }).then(()=>{
        console.log("saved");
        if(rowSelected.value.finished)
        {
            message.success(t('messages.reopened'))
        }
        else
        {
            message.success(t('messages.finished'))
        }
        rowSelected.value.finished = !rowSelected.value.finished
        if(showFinished.value != true)
        {
            dataShow.value = data.value.filter(item => item.finished == showFinished.value)
        }
    })
}

const getEvents = async () =>
{
        data.value = []
        await storeEvents.getEvents();
        console.log(storeEvents.events)
        storeEvents.events.forEach((e:any) => {
            var ev = {
                        id: e.id, 
                        name: e.name,
                        api_key: e.api_key,
                        activity: e.activity,
                        activity_name: e.activity.name,
                        date: new Date(e.date),
                        comments: e.comments,
                        logo: e.logo,
                        finished: e.finished,
                        demo: e.demo
                    }
            var finalEv:Event = ev;
            
            data.value.push(finalEv);
    });
};

const events_table = ref(null)

const filterAddress = () => {
    showFinished.value = !showFinished.value

    if(showFinished.value == true)
    {
        dataShow.value = data.value
    }
    else
    {
        dataShow.value = data.value.filter(item => item.finished == showFinished.value)
    }
}

const showFinished = ref(false);
const dataShow = ref<Event[]>([]);
const loaded = ref(false)
onMounted( async () => {
    await getEvents();
    // dataShow.value = data.value.filter(item => item.finished == showFinished.value)
    console.log(props.demo);
    console.log(data.value);
    
    if(props.demo)
    {
        dataShow.value = data.value.filter(item => item.demo == true)
    }
    else
    {
        dataShow.value = data.value.filter(item => item.demo == false)
    }

    loaded.value = true;
})

const pagination = { pageSize: 10, total: data.value.length };

const columns = createColumns({
            play(row: Event) {
                message.info(`Event ${row.activity}`)
            }
        })
</script>


import { axiosToken, simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";
import type { List } from "lodash";
import { defineStore, acceptHMRUpdate } from "pinia"
import { useTapasStore } from "./tapas";
import type { TapasChallenge } from "./interfaces/tapas_interfaces";
import { translate, translateAll } from "@/utils/translate";
import { initDB , checkImageDb} from "@/utils/indexeddb";
type Challenge = {
	id: number, 
	name: string, 
	description: string, 
	image: string, 
	material: string, 
	scoring_description: string
}


export const useCalendarStore = defineStore("calendar", {
	state: () => ({
		calendarData:[],
	}),
	actions: {
		
	},
	persist: {
		// paths: ["events"]
	},
	getters:{
		getCalendarData: state => (showBookings: boolean, showBudgets: boolean): any | undefined => {
			if (state.calendarData.length == 0) {
				return []
			}
			const returnData: Array<any> = []
			const bookingBudgetIds = new Set()

			// First pass: collect budget IDs from bookings
			if (state.calendarData.bookings) {
				state.calendarData.bookings.forEach(booking => {
					if (booking.budget) {
						bookingBudgetIds.add(booking.budget)
					}
				})
			}

			for (let i = 0; i < Object.keys(state.calendarData).length; i++) {
				const typeCalendar: Array<any> = state.calendarData[Object.keys(state.calendarData)[i]]
				const key = Object.keys(state.calendarData)[i]

				for (let index = 0; index < typeCalendar.length; index++) {
					const e = typeCalendar[index]
					if (e.date != null) {
						// Skip budgets that have associated bookings
						if (key === 'budgets' && bookingBudgetIds.has(e.id)) {
							continue
						}

						if(key === 'bookings' && e.state == 'CANCELED')
						{
							continue
						}

						let start_time = '00:00:00'
						let allDay = false
						if (e.time != null) {
							start_time = e.time
						} else {
							allDay = true
						}
						const startDate = new Date(e.date + ' ' + start_time)
						const endDate = new Date(e.date + ' ' + start_time)
						endDate.setHours(startDate.getHours() + 2)
						let activities = ""

						if (key == "bookings") {
							console.log(e);
							
							if (e.booking_items !== undefined) {
								for (let index = 0; index < e.booking_items.length; index++) {
									if(e.booking_items[index].activity != null)
									{
										const element = e.booking_items[index]
										activities += element.name
										if (index < e.booking_items.length - 1) 
										{
											activities += "<br>"
										}
									}
								}
							}
						}

						const newObj = {
							type: Object.keys(state.calendarData)[i],
							id: e.id,
							start: startDate,
							end: endDate,
							title: e.name,
							activities: activities,
							pax: e.pax + " pax",
							language: e.language,
							allDay,
							location: e.place_url,
							location_name: e.place_name,
							fundae: e.fundae,
							staff_number: e.staff_number,
							teams_number: e.team_number,
						}
						if (showBookings && key == "bookings") {
							newObj.state = e.state
							returnData.push(newObj)
						}
						if (showBudgets && key == "budgets") {
							returnData.push(newObj)
						}
					}
				}
			}

			return returnData
		},
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useCalendarStore, import.meta.hot))
}

<template>
    <span>
        <div class="flex flex-col w-full h-52 items-center justify-center splash-screen-loading-box" v-if="loading">
            <n-spin :size="80" />
        </div>
        <div v-else class="page">
            <n-form
                ref="formRef"
                :model="storeEvents.activityConfiguration"
                :rules="rules"
                :size="size"
                label-placement="top"
            >
                <n-grid :x-gap="24">
                    <n-form-item-gi :span="12" label="Pruebas" path="challenges">
                        <n-transfer
                            v-model:value="storeEvents.activityConfiguration.challenges"
                            style="width: 100%"
                            :options="challengesOptions"
                        />
                    </n-form-item-gi>
                    <n-form-item-gi :span="12" label="Tiempo por ronda (segundos)" path="roundDuration">
                        <n-input-number v-model:value="storeEvents.activityConfiguration.round_duration" />
                    </n-form-item-gi>
                </n-grid>
            </n-form>
        </div>
    </span>
</template>

<script lang="ts" setup>
import Icon from "@/components/common/Icon.vue"
import { NButton, NForm, NFormItemGi, NInputNumber, NTransfer, useMessage, NSpin, NGrid, type FormItemRule,} from "naive-ui"
import { computed, onMounted, ref, Ref } from "vue"
import { type LabelPlacement, type Size, FormInst } from "naive-ui/es/form/src/interface"
import { useRoute, useRouter } from "vue-router"
import { useEventsStore } from "@/stores/apps/events"
import { axiosToken, fetchPromiseAll } from "@/utils/http";
import type { AxiosResponse } from "axios";
import { useUTQStore } from "@/stores/apps/utq"
import { useContentStore } from "@/stores/apps/content"
import { useStoreI18n } from "@/composables/useStoreI18n"

const { t } = useStoreI18n();
const ArrowLeft = "carbon:arrow-left"

const storeEvents = useEventsStore()
const storeContent = useContentStore()
const storeUTQ = useUTQStore()

const SaveIcon = "carbon:save"

const route = useRoute()
const router = useRouter()
const size = ref<Size | undefined>("medium")
const challengesOptions = ref([]) 
const loading = ref(true)
const formRef = ref<FormInst | null>(null)
onMounted( async () => {
    storeEvents.activityConfiguration = {challenges: null, round_duration: null, activity_formRef: formRef}
    if(route.params.api_key)
    {
        await fetchPromiseAll ([
            storeEvents.getEventFull(String(route.params.api_key)),
            storeContent.getAllContent("challenge"),
            storeUTQ.getUTQData(String(route.params.api_key))
        ])
    // var eventData = storeEvents.eventSelected;
    
        storeEvents.activityConfiguration.challenges = storeUTQ.utqData.challenges.map(v => v.id)
        storeEvents.activityConfiguration.round_duration = storeUTQ.utqData.round_duration
    }
    else
    {
        await fetchPromiseAll ([
            storeContent.getAllContent("challenge"),
        ])
    }
    
    challengesOptions.value = storeContent.content.challenge.map(v => ({label: v.name, value: v.id}))
    
    loading.value = false
});

const rules = {
            challenges: {
                type: 'array',
                required: true,
                trigger: 'change',
                message: t('gm.dashboards.select_challenge')
            },
            round_duration: {
                type: 'number',
                required: true,
                validator (rule: FormItemRule, value: string) {
                    if (Number(value) <= 0) {
                        return new Error(t('gm.dashboards.time_higher_zero'))
                    } else if (!value) {
                        return new Error(t('gm.dashboards.time_required'))
                    } else if (!/^\d*$/.test(value)) {
                        return new Error(t('gm.dashboards.time_integer'))
                    }
                    return true
                },
                trigger: ['blur', 'change'],
            },
        };

</script>

<style lang="scss" scoped>
.components-list {
	grid-template-columns: none;
}
.card-css {
    background-color: var(--bg-color);
}
</style>

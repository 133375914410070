import { axiosToken, simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";
import type { List } from "lodash";
import { defineStore, acceptHMRUpdate } from "pinia"
import { useTapasStore } from "./tapas";
import type { TapasChallenge } from "./interfaces/tapas_interfaces";
import { translate, translateAll } from "@/utils/translate";
import { useEventsStore } from "./events";


export const useChatStore = defineStore("chat", {
	state: () => ({
		chat_messages: []
	}),
	actions: {
        setChatMessages(messages:[], id_team:number){
			if(this.chat_messages.length > 0)
			{

				const team_messages = this.chat_messages.find(c => c.id == id_team)
				if(team_messages != undefined)
				{
					team_messages.messages = messages.sort((a,b) => {return new Date(a.create_at) - new Date(b.create_at)})
				}
				else
				{
					this.chat_messages.push({id_team: id_team, messages: messages.sort((a,b) => {return new Date(a.create_at) - new Date(b.create_at)})})
				}
			}
			else
			{
				console.log("Here");
				console.log(id_team);
				
				
				this.chat_messages.push({id_team: id_team, messages: messages.sort((a,b) => {return new Date(a.create_at) - new Date(b.create_at)})})
			}
        },
		updateTeamMessages(message:{}, id_team:number)
		{
			console.log(id_team)
			const chat_messages = this.chat_messages.find(c => c.id_team == id_team)

			let teamMessages = []
			if(chat_messages != undefined)
			{
				teamMessages = chat_messages.messages
			}
			const oldMessageIndex = teamMessages.findIndex(m => m.id == message.id)
			
			if(oldMessageIndex != -1)
			{				
				teamMessages[oldMessageIndex] = message
			}
			else if(teamMessages.length <= 0)
			{
				this.chat_messages.push({id_team: id_team, messages: [message]})
			}
			else
			{
				teamMessages.push(message)
			}
		}
	},
	persist: {
		// paths: ["events"]
	},
	getters:{
		getTeamChat:state =>{
			if( state.chat_messages.length > 0)
			{
				return state.chat_messages[0].messages;
			}
			return []
		},
		getTeamUnreadedChats:  state => (id_team: number) => {
			const teamChats = state.chat_messages.find(c => c.id_team == id_team)
			if(teamChats == undefined)
			{
				return 0
			}
			return teamChats.messages.filter(m => m.read_user == false && m.reciver == id_team).length
		},
		getUnreadedChats: state => () => {
			const result:any[] = []
			state.chat_messages.forEach( c => {
				const teamChats = state.chat_messages.find(c => c.id_team == id_team)
				if(teamChats == undefined)
				{
					result.push({id_team: 0, unreaded_messages: 0})
				}
				else
				{
					result.push({id_team: teamChats.id_team, unreaded_messages: teamChats.messages.filter(m => m.read_user == false).length})
				}
			})
			
			return result
		},
		getGameMasterUnreadedChats:  state => (id_team: number) => {
			const teamChats = state.chat_messages.find(c => c.id_team == id_team)
			
			if(teamChats == undefined)
			{
				return 0
			}
			
			return teamChats.messages.filter(m => m.read_user == false && m.reciver_gm == true).length
		},
		getGameMasterTeamsUnreadedChats(state):number {
			let totalTeamsUnreaded = 0
			state.chat_messages.forEach( c => {

			if( this.getGameMasterUnreadedChats(c.id_team))
			{
				totalTeamsUnreaded++
			}
			})
			
			return totalTeamsUnreaded
		},
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useChatStore, import.meta.hot))
}

import { createRouter, createWebHistory } from "vue-router"
import Events from "@/views/Events.vue"
import EventCRUD from "@/views/EventCRUD.vue"
import { Layout } from "@/types/theme.d"
import { authCheck } from "@/utils/auth"
import Content from "@/views/Content.vue"
import Settings from "@/views/Settings.vue"
import BlankPage from "@/views/BlankPage.vue"

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/",
			redirect: () => {
				// the function receives the target route as the argument
				// we return a redirect path/location here.
				return { path: '/login' }
			},
		},
		{
			path: "/events_list",
			name: "Events",
			component: Events,
			meta: { title: "Events page", auth: true, roles: "all" }
		},
		{
			path: "/events",
			name: "Event Add",
			component: EventCRUD,
			meta: { title: "Add event", auth: true, roles: "all" }
		},
		{
			path: "/events/:api_key",
			name: "Event Update",
			component: EventCRUD,
			meta: { title: "Event CRUD page", auth: true, roles: "all", showMenu: true }
		},
		{
			path: "/demos_list",
			name: "Demos",
			component: () => import("@/views/Demos.vue"),
			meta: { title: "Events page", auth: true, roles: "all" }
		},
		{
			path: "/profile",
			name: "Profile",
			component: () => import("@/views/Profile.vue"),
			meta: { title: "Profile", auth: true, roles: "all", showMenu: true }
		},
		{
			path: "/events/dashboard/:api_key",
			name: "Dashboard",
			component: () => import("@/views/Dashboard.vue"),
			meta: { title: "Dashboard", auth: true, roles: "all", showMenu: true }
		},
		// {
		// 	path: "/events/activity/:api_key",
		// 	name: "ActivityConfiguration",
		// 	component: () => import("@/views/ActivityConfiguration.vue"),
		// 	meta: { title: "ActivityConfiguration", auth: true, roles: "all", showMenu: true }
		// },
		{
			path: "/event/:api_key",
			name: "Event",
			component: () => import("@/views/instructor/InstructorGateway.vue"),
			meta: { title: "Event", auth: false, roles: "all", forceLayout: Layout.Blank }
		},
		{
			path: "/ranking/:api_key",
			name: "Ranking",
			component: () => import("@/views/public-views/PublicViewRankingGateway.vue" ),
			meta: { title: "Ranking", auth: false, roles: "all", forceLayout: Layout.Blank}
		},
		{
			path: "/final_images/:api_key",
			name: "FinalImages",
			component: () => import("@/views/public-views/action_challenge/FinalImages.vue" ),
			meta: { title: "Ranking", auth: false, roles: "all", forceLayout: Layout.Blank}
		},
		{
			path: "/player_view/:api_key",
			name: "PlayerViewEvent",
			component: () => import("@/views/player-views/Event.vue" ),
			meta: { title: "PlayerViewEvent", auth: false, roles: "all", forceLayout: Layout.Blank}
		},
		{
			path: "/player_view/:api_key/:team_api_key",
			name: "PlayerView",
			component: () => import("@/views/player-views/PlayerView.vue" ),
			meta: { title: "PlayerView", auth: false, roles: "all", forceLayout: Layout.Blank}
		},
		
		// {
		// 	path: "/",
		// 	name: "PlayerHome",
		// 	component: () => import("@/views/player-views/Home.vue" ),
		// 	meta: { title: "PlayerHome", auth: false, roles: "all", forceLayout: Layout.Blank}
		// },
		{
			path: "/results/:api_key",
			name: "Results",
			component: () => import("@/views/public-views/PublicViewResultsGateway.vue"),
			meta: { title: "Results", auth: false, roles: "all", forceLayout: Layout.Blank}
		},
		{
			path: "/custom_view/:api_key",
			name: "CustomView",
			component: () => import("@/views/public-views/CustomViewGateway.vue"),
			meta: { title: "CustomView", auth: false, roles: "all", forceLayout: Layout.Blank}
		},
		{
			path: "/login",
			name: "Login",
			component: () => import("@/views/Auth/Login.vue"),
			meta: { title: "Login", forceLayout: Layout.Blank, checkAuth: true }
		},
		{
			path: "/logout",
			name: "Logout",
			redirect: "/login"
		},
		{
			path: "/ingredient/:api_key",
			name: "Ingredint List",
			component: () => import("@/views/Tapas/IngredientList.vue"),
			meta: { title: "PlayerHome", auth: false, roles: "all", forceLayout: Layout.Blank}
		},
		{
			path: "/:pathMatch(.*)*",
			name: "NotFound",
			component: () => import("@/views/NotFound.vue"),
			meta: { forceLayout: Layout.Blank }
		},
		{
			path: "/content",
			name: "Content",
			component: Content,
			meta: { title: "Content page", auth: true, roles: "all" }
		},
		{
			path: "/content/:name_id",
			name: "ActivityType",
			component: () => import("@/views/Content/TypeContent.vue"),
			meta: { title: "Activity Type page", auth: true, roles: "all" }
		},
		{
			path: "/content/:name_id/:type",
			name: "ContentTypeAdd",
			component: () => import("@/views/Content/CRUDGateway.vue"),
			meta: { title: "Content Type Add page", auth: true, roles: "all" }
		},
		// PREVIEW IPAD 
		{
			path: "/content_preview/:name_id/:id",
			name: "Content Preview",
			component: () => import("@/views/Content/Preview/GatewayPreview.vue"),
			meta: { forceLayout: Layout.Blank, title: "Content Preview", auth: true, roles: "all" }
		},
		{
			path: "/content/:name_id/:type/:id",
			name: "ContentTypeEdit",
			component: () => import("@/views/Content/CRUDGateway.vue"),
			meta: { title: "Content Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/settings",
			name: "Settings",
			component: Settings,
			meta: { title: "Settings page", auth: true, roles: "all" }
		},
		{
			path: "/Settings/:name_id",
			name: "TypeSettings",
			component: () => import("@/views/Settings/TypeSettings.vue"),
			meta: { title: "Type Settings page", auth: true, roles: "all" }
		},
		{
			path: "/settings/:name_id/:type",
			name: "SettingsTypeAdd",
			component: () => import("@/views/Settings/CRUD/SettingsCRUDGateway.vue"),
			meta: { title: "Settings Type Add page", auth: true, roles: "all" }
		},
		{
			path: "/settings/:name_id/:type/:id",
			name: "SettingsTypeEdit",
			component: () => import("@/views/Settings/CRUD/SettingsCRUDGateway.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},

		// config ERP
		{
			path: "/organization/:id?",
			name: "organization",
			component: () => import("@/views/ERP/configuration/organization/organization.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/activities",
			name: "Actividades",
			component: () => import("@/views/ERP/configuration/activity/activities.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/activities/activity",
			name: "ActividadesDetailNew",
			component: () => import("@/views/ERP/configuration/activity/activitiesDetail.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/activities/activity/:id",
			name: "ActividadesDetail",
			component: () => import("@/views/ERP/configuration/activity/activitiesDetail.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},

		{
			path: "/extras/",
			name: "extras",
			component: () => import("@/views/ERP/configuration/extras/ExtrasList.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/extras/extras_detail/",
			name: "ExtrasDetailNew",
			component: () => import("@/views/ERP/configuration/extras/ExtrasDetail.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/extras/extras_detail/:id",
			name: "ExtrasDetail",
			component: () => import("@/views/ERP/configuration/extras/ExtrasDetail.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		
		{
			path: "/final_budget/",
			name: "finalBudget",
			component: () => import("@/views/ERP/configuration/finalBudget/FinalBudgetList.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/final_budget/budgetPage/",
			name: "FinalBudgetNew",
			component: () => import("@/views/ERP/configuration/finalBudget/FinalBudgetDetail.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/final_budget/budgetPage/:id",
			name: "FinalBudgetDetail",
			component: () => import("@/views/ERP/configuration/finalBudget/FinalBudgetDetail.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/question_booking",
			name: "QuestionBookingList",
			component: () => import("@/views/ERP/configuration/questionBooking/QuestionBookingList.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/question_booking/question_booking_detail/",
			name: "QuestionBookingDetailNew",
			component: () => import("@/views/ERP/configuration/questionBooking/QuestionBookingDetail.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/question_booking/question_booking_detail/:id",
			name: "QuestionBookingDetail",
			component: () => import("@/views/ERP/configuration/questionBooking/QuestionBookingDetail.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},

		// SALES MODULE

		// BUDGET 
		{
			path: "/budget/",
			name: "Budget",
			component: () => import("@/views/ERP/budget/BudgetList.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/budget/budgetdetail/",
			name: "BudgetDetailNew",
			component: () => import("@/views/ERP/budget/BudgetDetails.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/budget/budgetdetail/:id",
			name: "BudgetDetail",
			component: () => import("@/views/ERP/budget/BudgetDetails.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		

		// Clients 
		{
			path: "/client/",
			name: "Client",
			component: () => import("@/views/ERP/client/ClientList.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/client/clientdetail/:id",
			name: "ClientDetail",
			component: () => import("@/views/ERP/client/ClientDetail.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},

		// Clients 

		{
			path: "/booking/booking_detail_client/:api_key",
			name: "BookingDetailClient",
			component: () => import("@/views/ERP/booking/BookingDetailClient.vue"),
			meta: { title: "Booking Detail", auth: false, roles: "all", forceLayout: Layout.Blank }
		},

		// GAME MASTERS

		{
			path: "/booking/booking_detail_gm/:id",
			name: "BookingDetailGM",
			component: () => import("@/views/ERP/booking/BookingDetailGM.vue"),
			meta: { title: "Booking Detail", auth: true, roles: "all" }
		},

		// Players
		{
			path: "/games/:api_key",
			name: "PlayerHome",
			component: () => import("@/views/player-views/Home.vue" ),
			meta: { title: "PlayerHome", auth: false, roles: "all", forceLayout: Layout.Blank}
		},

		// BOOKING

		// Calendar

		{
			path: "/calendar/",
			name: "Calendar",
			component: () => import("@/views/ERP/booking/calendar/Calendar.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},

		// List
		
		{
			path: "/booking_list/",
			name: "BookingList",
			component: () => import("@/views/ERP/booking/BookingList.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/booking/booking_detail/",
			name: "BookingDetailNew",
			component: () => import("@/views/ERP/booking/BookingDetail.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},
		{
			path: "/booking/booking_detail/:id",
			name: "BookingDetail",
			component: () => import("@/views/ERP/booking/BookingDetail.vue"),
			meta: { title: "Settings Type Edit page", auth: true, roles: "all" }
		},

		// SLACK 
		{
			path: "/organization/slack",
			name: "InstallSlack",
			component: () => import("@/views/ERP/slack/InstallSlack.vue"),
			meta: { title: "Install Slack", auth: true, roles: "all" }
		},
		
		{
			path: '/user',
			name: 'UserSettings',
			component: () => import("@/views/User.vue"),
			meta: { title: "User Settings", auth: true, roles: "all" }
		},
	]
	
})

router.beforeEach(route => {
	return authCheck(route)
})

export default router
<template>
	<div class="sidebar-footer" :class="{ collapsed }">
		<n-menu :options="menuOptions" :collapsed="collapsed" :collapsed-width="collapsedWidth" />
	</div>
</template>

<script lang="ts" setup>
import { computed, h, ref, toRefs, watch } from "vue"
import { NMenu } from "naive-ui"
import { useThemeStore } from "@/stores/theme"
import { useAuthStore } from "@/stores/auth"
import { renderIcon } from "@/utils"
import Logout from "@/app-layouts/common/Toolbar/Logout.vue"
import { useRouter } from "vue-router"
import { useNotificationStore } from "@/stores/apps/notification"
import BellNotificationIcon from "@/customComponents/BellNotificationIcon.vue"
import { useStoreI18n } from "@/composables/useStoreI18n"

const LogoutIcon = "ion:log-out-outline"
const UserIcon = "ion:person-outline"

const storeNotification = useNotificationStore()
const storeAuth = useAuthStore()

defineOptions({
	name: "SidebarFooter"
})
const props = withDefaults(
	defineProps<{
		collapsed?: boolean
	}>(),
	{ collapsed: false }
)
const { collapsed } = toRefs(props)
const { t } = useStoreI18n()
const menuOptions = ref([
	{
		label: () =>
		h(
			'button', // Using a button instead of RouterLink
			{
				class: 'menu-button' // You can style the button as needed
			},
			storeAuth.user?.email || 'User' // Display email or fallback to 'User'
		),
		onClick: openUserProfile, // You'll need to implement this function
		key: 'User',
		icon: renderIcon(UserIcon)
	},
	{
		label: () =>
		h(
			'button', // Using a button instead of RouterLink
			{
				class: 'menu-button' // You can style the button as needed
			},
			t("menu.notifications")
		),
		onClick: openNotifications,
		key: 'Notification',
		icon: renderIcon(
			h(
				BellNotificationIcon,
				{
					newNotification: storeNotification.getUnreadNotifications(), 
					iconName: 'ion:notifications-outline', 
				}
			)
		) // Use the icon you want
	},
	{
		label: () =>
		h(
			'button', // Using a button instead of RouterLink
			{
				class: 'menu-button' // You can style the button as needed
			},
			t("menu.logout")
		),
		onClick: logout,
		key: 'Logout',
		icon: renderIcon(LogoutIcon) // Use the icon you want
	}
])
watch(storeNotification.getUnreadNotifications, () => {
	menuOptions.value[1].icon = renderIcon(
		h(
			BellNotificationIcon, { newNotification: storeNotification.getUnreadNotifications() }
		)
	)
})

const themeStore = useThemeStore()

const collapsedWidth = computed<number>(() => themeStore.sidebar.closeWidth - 16)
const router = useRouter()
function logout(e?: MouseEvent) {
	storeAuth.setLogout()
    router.push("/login")
	return e
}

function openNotifications ()
{
	const notificationStore = useNotificationStore();
	notificationStore.showDrawer = !notificationStore.showDrawer
}

function openUserProfile() {
	router.push('/user')
}
</script>

<style lang="scss" scoped>
.sidebar-footer {
	margin: 8px;
	background-color: var(--bg-body);
	border-radius: var(--border-radius);
	padding: 3px 0;
	transition: all 0.3s;

	:deep() {
		.n-menu {
			.n-menu-item-content.n-menu-item-content--selected::before {
				background-color: transparent !important;
			}
		}
	}
}
</style>
import { axiosToken, simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";
import { defineStore, acceptHMRUpdate } from "pinia"
import {type TapasChallenge, type TapasData, type VotesData} from "@/stores/apps/interfaces/tapas_interfaces"
import { useContentStore } from "./content";
import { useEventsStore } from "./events";
import dayjs from "@/utils/dayjs";
import { useStoreI18n } from '@/composables/useStoreI18n';
import i18n from "@/utils/i18n";

const { t } = i18n.global
export const useTapasStore = defineStore("tapas", {
	state: () => ({tapasData: {} as TapasData, votesData: [] as VotesData[] , time:null } ),
	actions: {
		async getTapasData (api_key:string)
		{
			await simpleAxios("get", "events/event_full/" + api_key, {})
			.then((response: AxiosResponse) => {
                console.log(response.data);
                
                this.tapasData = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async setTapasData (data:TapasData)
		{
            this.tapasData = data
		},
		async getVotesData (api_key:string )
		{
			await axiosToken("get", "Tapas/vote/" + api_key, {})
			.then((response: AxiosResponse) => {
                console.log(response.data);
                
                this.votesData = response.data
			})
			.catch((e: any) => {
				console.log(e);
			});
		},
		async setVotesData (data:VotesData)
		{
            this.votesData = data
		},
	},
	getters: {
		getTeamData: state=> (id:number): any | undefined  =>{
			try
			{
				return state.tapasData.data_ingredients_team[id];
			}
			catch
			{
				return {};
			}
		},
		getTeamIngridients: state=> (id:number): any | undefined  =>{
			try{
				let list_ing =[]
				list_ing = Object.assign([], state.tapasData.data_ingredients_team[id].ingredients);	
				list_ing.push(state.tapasData.data_ingredients_team[id].main)
				// console.log(list_ing);
				const lastElement = list_ing.pop();

				// Add the removed element to the beginning of the array
				list_ing.unshift(lastElement);

				return list_ing
			}
			catch
			{
				return [];
			}
		},
		getIngridientChoose: state =>{
			try{
				const contentStore = useContentStore();
				let list = []
				for (const [key, value] of Object.entries(state.tapasData.ingredient_list)) {
					if(value > 0)
					{
						list.push(key)
					}
				}	
				return list
			}
			catch
			{
				return [];
			}
		},
		getTeamTurn: state=>(id:number):any => {
			if ( state.tapasData.current_team_choice == undefined)
			{
				return false;
			}
			if(state.tapasData.current_team_choice.id == id)
			{
				return true;
			} 
			else
			{
				return false;
			}
		},
		getIngredientAble: state=>(id:number):any => {
			if(state.tapasData.items_to_choice[id] > 0)
			{
				return true;
			} 
			else
			{
				return false;
			}
		},
		getIngredientQuantity: state=>(id:number):any => {
			return state.tapasData.items_to_choice[id]
		},
		getFinalTime: state =>{
			if(state.tapasData.tapas_timer_finish == undefined)
			{
				return;
			}
			
			return new Date(state.tapasData.tapas_timer_finish);
		},
		getChallengeFinishTime: state =>{
			if(state.tapasData.finish_timer_challenge == undefined)
			{
				return -1;
			}
			return new Date(state.tapasData.finish_timer_challenge);
		},
		getSecondsRoundRemaining: (state) =>{
			if(state.tapasData.timer == undefined)
			{
				return;
			}		
			return new Date(state.tapasData.timer.finish_round_time);
		},
		getChallengeRanking: state => (id:number)=>{
			let ranking_challenge =[]
			const eventStore = useEventsStore()			
			for (const [key, value] of Object.entries(state.tapasData.challenges_final_ranking)) {
				for (const [key2, value2] of Object.entries(value.challenge)) {
					if( key2 == id)
					{
						ranking_challenge.push({
							name: eventStore.getTeamNumberById(value.team_id),
							position: value2.position,
							points: value2.points
						})
					}
				}
			
			}
			const sortedData = ranking_challenge.sort((a, b) => a.position - b.position);
			
			return sortedData;
		},
		getTotalChoiceRounds:state=> {
			try{
				return state.tapasData.tapas_settings.decoration +  state.tapasData.tapas_settings.secondary 
			}
			catch
			{
				return [];
			}
		},
		// ranking
		RankingChallange:state=>{
			const new_ranking = []
			const eventStore = useEventsStore()
			if( state.tapasData.challenges_final_ranking== null)
			{
				return new_ranking
			}
			for (const [key, value] of Object.entries(state.tapasData.challenges_final_ranking)){
				const obj = {name: value.name, points: value.global_points, position: "0", full_name : eventStore.getTeamNumberById(value.team_id), id: parseInt(key)}
				for (const [key2, value2] of Object.entries(value["challenge"]))
				{
					obj[String(key2)] = value2.position + "º / " + value2.points
				}
				new_ranking.push(obj)
			}
			new_ranking.sort((a, b) => parseFloat(a.points) - parseFloat(b.points));
			if(new_ranking.length > 0)
			{
				new_ranking[0].position = "1º"
				let current_pos = 1
				let current_points = new_ranking[0].points
				for (let index = 1; index < new_ranking.length; index++) {
					if( current_points ==  new_ranking[index].points)
					{
						new_ranking[index].position = current_pos + "º"
					}
					else
					{
						current_pos = index + 1;
						current_points  = new_ranking[index].points
						new_ranking[index].position = current_pos + "º"
					}
					
				}
			}
			return new_ranking
		},
		getCurrentStore:state=>{	
			try
			{
				if(Object.keys(state.tapasData.current_store).length <= 0)
				{
					return {}
				}
				const filteredKeys = Object.keys(state.tapasData.current_store).filter(key => state.tapasData.current_store[key as keyof {}] > 0);

				const filteredObject: {} = filteredKeys.reduce((acc, key) => {
					acc[key] = state.tapasData.current_store[key as keyof {}];
					return acc;
					}, {});
					
				return filteredObject
			}
			catch
			{
				return [];
			}

		},
		getColumnIngredients:state=>{
			try
			{
				console.log("getColumnIngredients");
				
				let columns =[]
				columns.push({
					title: t("general.team"),
					key: "team"
				})
				columns.push({
					title: t("stores.tapas.main_ingredient"),
					key: "main"
				})
				for (let index = 0; index < state.tapasData.tapas_settings.secondary + state.tapasData.tapas_settings.decoration; index++) {
					columns.push({
						title: t("general.ingredient") + " " + (index+1),
						key: "ing" + (index+1) 
					})
				}

				
				return columns
			}
			catch
			{
				return [];
			}
		},
		getDataIngredients:state=>{
			try
			{
				let data =[]
				const eventStore = useEventsStore()
				const contentStore = useContentStore()

				console.log(state.tapasData.data_ingredients_team);
				
				for (const [key, value] of Object.entries(state.tapasData.data_ingredients_team)) {
					console.log(key, eventStore.getTeambyId(key).name);
					
					let data_temp = {
						team:eventStore.getTeambyId(key).name_id + ". " + eventStore.getTeambyId(key).name,
					}
					let counter = 1
					for (const element of value.ingredients) {
						data_temp["ing" + counter] = contentStore.getContentIngridient(element).name
						counter ++;
					}
					counter = 1
					data_temp["main"] = contentStore.getContentIngridient(value.main).name
					data.push(data_temp)
				}
				return data
			}
			catch
			{
				return [];
			}
		},
		getColumnFinalVote: state=>(publicView:boolean):any=>{
			let columns =[]
			
			columns.push({
				title: t("general.team"),
				key: "team"
			})
			columns.push({
				title: t("stores.tapas.global_winner"),
				key: "TOTAL",
				sorter: (row1, row2) => row1.TOTAL - row2.TOTAL
			})
			if( state.tapasData.ranking_savour)
			{
				columns.push({
					title: t("stores.tapas.flavour_winner"),
					key: "SAVOUR",
					sorter: (row1, row2) => row1.SAVOUR - row2.SAVOUR
				})
			}
			if( state.tapasData.ranking_presentation){
				columns.push({
					title: t("stores.tapas.presentation_winner"),
					key: "SHOW",
					sorter: (row1, row2) => row1.SHOW - row2.SHOW
				})
			}
			if( state.tapasData.ranking_defense){
				columns.push({
					title: t("stores.tapas.defense_winner"),
					key: "DEFENSE",
					sorter: (row1, row2) => row1.DEFENSE - row2.DEFENSE
				})
			}
			columns.push({
				title: t("stores.tapas.money_winner"),
				key: "MONEY",
				sorter: (row1, row2) => row1.MONEY - row2.MONEY
			})
			if( publicView == false)
			{
				columns.push({
					title: t("stores.tapas.tapa"),
					key: "name_tapa",
				}),
				columns.push({
					title: t("stores.tapas.photo_tapa"),
					key: "photo_tapa",
				}),
				columns.push({
					title: t("stores.tapas.photo_team"),
					key: "photo_team",
				})
			}
			return columns
		},
		getDataFinalVotes: state=>(publicView:boolean):any=>{
			let data =[]
			const eventStore = useEventsStore()
			console.log("-----------");
			// create for every team
			for (const element of eventStore.teams) {
				if( publicView == true)
				{
					try{
						const temp_data ={
							team:element.name_id + ". " + element.name,
							TOTAL:state.tapasData.final_ranking.TOTAL.find(e => e.id == element.id)["pos"],
							DEFENSE:state.tapasData.final_ranking.DEFENSE.find(e => e.id == element.id)["pos"],
							SAVOUR:state.tapasData.final_ranking.SAVOUR.find(e => e.id == element.id)["pos"],
							SHOW:state.tapasData.final_ranking.SHOW.find(e => e.id == element.id)["pos"],
							MONEY:state.tapasData.final_ranking.MONEY.find(e => e.id == element.id)["pos"],
						}
						data.push(temp_data)
					}
					catch(error){
						//console.error(error)
					}
				}
				else
				{
					try{
						let team = {name_tapa: '', photo_tapa: null, photo_team: null}
						if(eventStore.tapas_team.find(e => e.team.id == element.id) != undefined )
						{
							team = eventStore.tapas_team.find(e => e.team.id == element.id)
						}
						console.log(team);
						
						const temp_data ={
							team:element.name_id + ". " + element.name,
							TOTAL:state.tapasData.final_ranking.TOTAL ? state.tapasData.final_ranking.TOTAL.find(e => e.id == element.id)["pos"] : "",
							DEFENSE:state.tapasData.final_ranking.DEFENSE ? state.tapasData.final_ranking.DEFENSE.find(e => e.id == element.id)["pos"] : "",
							SAVOUR:state.tapasData.final_ranking.SAVOUR ? state.tapasData.final_ranking.SAVOUR.find(e => e.id == element.id)["pos"] : "",
							SHOW:state.tapasData.final_ranking.SHOW ? state.tapasData.final_ranking.SHOW.find(e => e.id == element.id)["pos"] : "",
							MONEY:state.tapasData.final_ranking.MONEY ? state.tapasData.final_ranking.MONEY.find(e => e.id == element.id)["pos"] : "",
							name_tapa: team.name_tapa != '' ? "OK" : "Falta",
							photo_tapa: team.photo_tapa != null ? "OK" : "Falta",
							photo_team: team.photo_team != null ? "OK" : "Falta",
						}
						data.push(temp_data)
					}
					catch(error){
						console.error(error)
					}
				}
			}

			// for (const [key, value] of Object.entries(state.tapasData.final_ranking)) {
			// 	for (const element of value.DEFENSE) {
					
			// 	}
				
			// }
			console.log(data);
			
			return data
		},

		getColumnsInstructorVotes:state=>
		{
			let columns =[]
			columns.push({
				title: "Item",
				key: "type_choice",
				sorter: (row1, row2) => row2.id - row1.id
			})
			columns.push({
				title: "Top 1",
				key: "top_1",
			})
			columns.push({
				title: "Top 2",
				key: "top_2",
			})
			columns.push({
				title: "Top 3",
				key: "top_3",
			})
			return columns
		},
		getInstructorVotes:state =>
		{
			const result:VotesData[] = []
			const storeEvents = useEventsStore()			
			state.votesData.forEach((e:VotesData) => {
				const team_1 = storeEvents.teams.find(t => t.id == e.top_1) 
				const team_2 = storeEvents.teams.find(t => t.id == e.top_2)
				const team_3 =	storeEvents.teams.find(t => t.id == e.top_3)
				result.push({
					id: e.id, 
					type_choice: e.type_choice, 
					top_1: team_1 != undefined ? team_1.name_id + ". " + team_1.name : "", 
					top_2: team_2 != undefined ? team_2.name_id + ". " + team_2.name : "", 
					top_3: team_3 != undefined ? team_3.name_id + ". " + team_3.name : "", 
					event: e.event,
					instructor: e.instructor,
					instructor_name: e.instructor.name,
				})
			});
			return result
		},
		getCheckChallangeDone: state => (team_id:number)=>{
			if ( state.tapasData.challenge_done_by_team.includes(team_id))
			{
				return true;
			}
			return false;
		},
		getTapasChallengeSelected: state => () => {
			const storeEvents = useEventsStore()
			
			if(state.tapasData.challenge_done_by_team != null) 
			{
				const teams = [] as Array<Object>

				
				storeEvents.teams.forEach(t => {
					if(!state.tapasData.challenge_done_by_team.includes(t.id))
					{
						t.done = false
						t.name_with_id = t.name_id + ". " + t.name
						teams.push(t)
					}
				});
				
				return teams.sort((a,b) => parseInt(a.name_id) - parseInt(b.name_id))
			}
			else
			{
				return storeEvents.teams.sort((a,b) => parseInt(a.name_id) - parseInt(b.name_id))
			}
		},
		getTapasChallenges: state => (): any | undefined  =>{
			const storeContent = useContentStore()
			if  (!( 'tapas_challenge' in storeContent.content))
			{
				return [];
			}
			else
			{
				const returnObjs = [{type: "group", label: " - Retos - ", key: "challenges", children: [] as Array<Object>}, 
									{type: "group", label: " - Retos realizados - ", key: "done_challenges", children: [] as Array<Object> } ]
				const tapas_challenge:TapasChallenge[] = storeContent.content["tapas_challenge"] as TapasChallenge[]
				
				tapas_challenge.forEach(e => {
					if(state.tapasData.current_challenge_done.find(challengeDone => challengeDone.id == e.id) )
					{
						returnObjs[1].children.push({label: e.name , value: e.id, disabled: true})
					}
					else
					{
						returnObjs[0].children.push({label: e.name , value: e.id})
					}
				});
				
				console.log(returnObjs);
				
				return returnObjs
			}
		}, 
		getDataOrders: state => () => {
			const storeEvents = useEventsStore()
			const storeContent = useContentStore()

			if(state.tapasData.order_queue.length == 0 || Object.keys(state.tapasData.order_queue).length == 0)
			{
				return []
			}
			
			const orders = [] as Array<Object>

			state.tapasData.order_queue.forEach(oq => {
				const team = storeEvents.getTeambyId(oq.team_id)
				const item = storeContent.getItemShop(parseInt(oq.item_id))
				orders.push({team: team, item: item, team_name: team.name, item_name: item.name, done: false, team_name_with_id: team.name_id + ". " + team.name})
			});

			return orders
		}
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useTapasStore, import.meta.hot))
}

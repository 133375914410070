<template>
    <span>
        <div class="flex flex-col w-full h-52 items-center justify-center splash-screen-loading-box" 
            v-if="!('activity' in storeEvents.eventSelected)">
            <n-spin :size="80" />
        </div>
        <div v-else-if="storeEvents.eventSelected.activity != undefined">
            <UTQForm v-if="storeEvents.eventSelected.activity.code == 'UTQ'"/>
            <TapasForm v-else-if="storeEvents.eventSelected.activity.code == 'Tapas' 
                        || storeEvents.eventSelected.activity.code == 'MS' 
                        || storeEvents.eventSelected.activity.code == 'PC'"/>
            <IpadHuntForm v-else-if="storeEvents.eventSelected.activity.code == 'IH'"/>
            <ActionForm v-else-if="storeEvents.eventSelected.activity.code == 'AC' || storeEvents.eventSelected.activity.code == 'ACS'"/>
        </div>
    </span>
</template>


<script lang="ts" setup>
import { useEventsStore } from "@/stores/apps/events"
import UTQForm from "@/views/ActivityCRUD/UTQ.vue"
import TapasForm from "@/views/ActivityCRUD/Tapas.vue"
import IpadHuntForm from "@/views/ActivityCRUD/IpadHunt.vue"
import ActionForm from "@/views/ActivityCRUD/Action.vue"
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { NSpin } from "naive-ui"

const storeEvents = useEventsStore()

const route = useRoute()

onMounted( async () => {
    if( 'api_key' in route.params && route.params.api_key != undefined)
    {
        await storeEvents.getEvent(String(route.params.api_key))
    }
});

</script>

<style lang="scss" scoped>
.components-list {
	grid-template-columns: none;
}
.card-css {
    background-color: var(--bg-color);
}
</style>

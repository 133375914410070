import locales from "."

export type MessageSchema = typeof locales.en
export type Locales = keyof typeof locales

export function getI18NConf() {
	let locale = null;
	if( localStorage.getItem("language") != null)
	{
		locale = localStorage.getItem("language")
	}
	else
	{
		const userLang = navigator.language || navigator.userLanguage;
		locale = userLang == 'en' || userLang == 'es' ? userLang : 
							localStorage.getItem("language") != undefined ? localStorage.getItem("language") == "Inglés" ? "en" : "es" 
						: 'es'
		localStorage.setItem("language", locale == "en" ? "en" : "es")

	}
	return {
		legacy: false,
		locale:  locale,
		messages: locales
	}
}

import { axiosToken, simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";
import type { List } from "lodash";
import { defineStore, acceptHMRUpdate } from "pinia"
import { useTapasStore } from "./tapas";
import type { TapasChallenge } from "./interfaces/tapas_interfaces";
import { translate, translateAll } from "@/utils/translate";
import { useEventsStore } from "./events";
import { useContentStore } from "./content";

interface Budget_Line {
    id:Number,
    name:String,
    pax:Number,
	price_tag?:String,
	activity:String,
}

export const useBudgetStore = defineStore("budget", {
	state: () => ({
		budgets:[],
		budget_lines:[] as Budget_Line[],
		extras: [],
		budget_page: []
	}),
	actions: {
		async setDefault()
		{
			this.budgets = []
			this.budget_lines = [] as Budget_Line[]
			this.extras = []
			this.budget_page = []
		},
        async fetchBudgets(){
			await axiosToken("get","budget/budget/").then((response)=>{
				this.budgets = response.data
			})
        },
		async fetchBudgetLine(budget:String){
			await axiosToken("get","budget/budget_line/" + budget +"/").then((response)=>{
				this.budget_lines = response.data.sort(function(a, b) {
					return a.id - b.id;
				});
			})
		},
		newLine(pax = 0){
			this.budget_lines.push({
				id:-1,
				name:"",
				pax:pax,
				activity:"",
			})
		},
		async UpdateDataLine(budgetId:Number, dataline:Budget_Line)
		{
			await axiosToken("put","budget/budget_line/" + budgetId+ "/" + dataline.id + "/", dataline)
		},
		async CreateDataLine(budgetId:Number, dataline:Budget_Line)
		{
			await axiosToken("post","budget/budget_line/" + budgetId+ "/", dataline)
		},
		async CreateDataLineBulk(budgetId:Number, dataline:Budget_Line[])
		{
			await axiosToken("post","budget/budget_line_bulk/" + budgetId+ "/", dataline)
		},
		async DeleteDataLine(budgetId:Number, dataline_id:Number)
		{
			await axiosToken("delete","budget/budget_line/" + budgetId+ "/" + dataline_id + "/")
		},
		async TrigerPdf(budgetId:Number)
		{
			await axiosToken("post","budget/budget_triger_pdf/" + budgetId+ "/")
		}


		
	},
	persist: {
		// paths: ["events"]
	},
	getters:{
		getOptionsTags:  state => (activity: number) => {
			// NEED ACTIVITY FROM THE OTHER STORE
			const contentStore = useContentStore();
			const activityData = contentStore.getContentByTypeById("activity", activity)
			const tags = []
			if( Array.isArray(activityData.price_rules))
			{
				activityData.price_rules.forEach(e =>{
					tags.push({
						value:e.name,
						label:e.name
					})
				})

			}
			return tags
		},
		getPricing:state =>(activity: number, pax:number, tag:String)=>{
			// NEED ACTIVITY FROM THE OTHER STORE
			const contentStore = useContentStore();
			const activityData = contentStore.getContentByTypeById("activity", activity)
			let price = 0;

			if(tag == 'Custom')
			{
				return price
			}

			if( Array.isArray(activityData.price_rules))
			{
				console.log("INNNNNN")
				const priceArray = activityData.price_rules.find(e=> e.name == tag)
				for (let index = 0; index < priceArray.list.length; index++) {
					const element = priceArray.list[index];
					console.log(element);
					
					if(pax >=  parseInt(element.pax_low)  && pax <= parseInt(element.pax_high))
					{
						price = element.price_total
						break;
					}
				}
			}
			console.log(pax,price);
			return price; 
		},
		getPaxInterval:state =>(activity: number, tag:String)=>{
			const contentStore = useContentStore();
			const activityData = contentStore.getContentByTypeById("activity", activity)
			const tagPax = []

			if(tag == 'Custom')
			{
				return ''
			}

			if( Array.isArray(activityData.price_rules))
			{
				const priceArray = activityData.price_rules.find(e=> e.name == tag)
				for (let index = 0; index < priceArray.list.length; index++) {
					const element = priceArray.list[index];
					tagPax.push({
						"label": element.pax_low + ' - ' + element.pax_high,
						"value": index,
					})
				}
			}
			return tagPax
		},
		getPriceByIndex:state =>(activity: number, index:number, tag:String)=>{
			const contentStore = useContentStore();
			const activityData = contentStore.getContentByTypeById("activity", activity)
			let price = 0;
			if( Array.isArray(activityData.price_rules))
			{
				const priceArray = activityData.price_rules.find(e=> e.name == tag)
				price = priceArray.list[index].price_total
			}
			console.log(price);
			return price; 
		}
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useBudgetStore, import.meta.hot))
}

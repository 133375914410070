<template>
    <div class="flex relative justify-end items-start ">
        <Icon :name="icon" />
        <div v-if="newNotification" class="rounded-full bg-red-500 text-white w-2 h-2 absolute flex items-center justify-center text-[11px]"/>
    </div>
</template>
<script lang="ts" setup>
import { NButton, NForm, NFormItem, NInput,NSpin, NModal, FormInst, type UploadFileInfo, NDateLocale, useMessage } from "naive-ui"
import {shallowRef, ref, h, onMounted, onBeforeMount, watch, toRaw, computed } from "vue"
import { Loader } from "@googlemaps/js-api-loader"
import Icon from "@/components/common/Icon.vue"
import { ipadWs, ipadWsSend } from "@/utils/ws/ipad_ws";
import { useRoute } from "vue-router";
import { TeamWs } from "@/utils/ws/team_ws";
import { useIpadHuntStore } from "@/stores/apps/ipad_hunt";
import { useEventsStore } from "@/stores/apps/events";
import { useChatStore } from "@/stores/apps/chat";
import daysjs from "@/utils/dayjs"
import { simpleAxios } from "@/utils/http";
import type { AxiosResponse } from "axios";

const props = defineProps({
    newNotification: {
        type: Boolean,
        default: false
    },
    iconName: {
        type: String,
        default: "ion:chatbubbles"
    },
})

const configuration = ref<HTMLDivElement | null>(null);

// ICONS
const icon = props.iconName

const eventStore = useEventsStore();

const config = ref({team_name: ""})



onMounted( () => {
    
})


</script>
<style>

</style>
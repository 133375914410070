//

import Dexie from "dexie"

// USE THE SAME DATABASE
// content data base
const db = new Dexie("Content")

const initDB = async () => {
	db.version(1).stores({
		// ipadHunt
		challenge: "id, data, file1, file2, filte3",
		answers: " challenge, team_id, answer",
		file_upload: "challenge, team_id, file",

		// Content image:
		ingredient_image: "id, file, url",
		item_shop_image: "id, file, url",
		tapas_challenge_image: "id, file, url"
	})
	// console.log("init db");
	await db.open()
	return db
}

const checkImageDb = async (contentName, instance) => {
	await db.open()
	for (const key in instance) {
		if (
			typeof instance[key] === "string" &&
			instance[key].includes("digitaloceanspaces.com") &&
			!key.includes("pdf")
		) {
			// is an image
			// check if is in database

			const name_db = contentName + "_" + key
			let blob
			await ensureTableExists(db, 
				name_db,"id, file, url"
			)

			// console.log(name_db, instance.id);

			const record = await db.table(name_db).where("id").equals(instance.id).toArray()
			//console.log(record[0]);

			if (record.length > 0) {
				if (instance[key].split("?")[0] == record[0].url.split("?")[0]) {
					instance[key] = URL.createObjectURL(record[0].file)
				} else {
					const res = await fetch(instance[key]) // add retry may
					blob = await res.blob()
					db.table(name_db)
						.where("id")
						.equals(instance.id)
						.modify(item => {
							item.file = blob
							item.url = instance.image
						})
					instance[key] = URL.createObjectURL(blob)
				}
			} else {
				// save downloaded and save
				// hey
				const res = await fetch(instance[key]) // add retry may
				blob = await res.blob()
				await db.table(name_db).add({
					id: instance.id,
					file: blob,
					url: instance[key]
				})
				instance[key] = URL.createObjectURL(blob)
			}
		}
	}
	return instance
}

// Function to check if a table exists
async function ensureTableExists(db, tableName, schema) {
	try {
		// Open the database
		await db.open()

		if (!db.tables.some(table => table.name === tableName)) {
			// If the table doesn't exist, add it dynamically
			await db.close()
			db.version(db.verno + 1).stores({
				[tableName]: schema
			})
			// Reopen the database with the new version
			await db.open()
		} else {
			// console.log(`Table '${tableName}' already exists.`)
		}
	} catch (error) {
		console.error("Error ensuring table exists:", error)
	}
}
export { initDB, checkImageDb }

import { simpleAxios } from "@/utils/http"
import Dexie from "dexie"
import type { AxiosResponse } from "axios"
import { useIpadHuntStore } from "@/stores/apps/ipad_hunt"
import { useEventsStore } from "@/stores/apps/events"
import { sound_alert } from  "@/utils/notifications";

const initDB = () => {
	// Dexie.delete('Content');
	const db = new Dexie("Content")
	db.version(1).stores({
		challenge: "id, data, file1, file2, file3,match_a,match_b,match_c,match_d,match_e,match_f,match_1,match_2,match_3,match_4,match_5,match_6",
		answers: " challenge, team_id, answer",
		file_upload: "challenge, team_id, file, name",
		challenge_done: " challenge",
		challenge_started:"id, done, challenge, start, force, start_time"
	})
	return db
}

const match_images_key = [
	"match_a","match_b","match_c","match_d","match_e","match_f","match_1","match_2","match_3","match_4","match_5","match_6"
]

const files = [
	"file_1_es",
	"file_2_es",
	"file_3_es",
	"file_1_en",
	"file_2_en",
	"file_3_en",
]


const checkContentDownloaded = async (event_api_key: string) => {
	// create the data base is not created
	const ipadStore = useIpadHuntStore()
	// Dexie.delete('Content');
	const db = initDB()

	// get all challanges and check if are stored
	let challenges

	await simpleAxios("get", "IpadHunt/challenge/" + event_api_key).then(async (response: AxiosResponse) => {
		challenges = response.data
		ipadStore.player_challanges = challenges
		ipadStore.counterContentDownload = 0;
		ipadStore.maxContentDownload = challenges.length
		// check if user is in database
			challenges
				.forEach(async element => {
					const challengeDb = await db.challenge.where("id").equals(element.challenge).first()

					if (challengeDb == undefined) {
						// GET CONTENT HERE AND SAVE
						await simpleAxios("get", "content/gateway/ipad_challenge/" + element.challenge)
							.then(async (response2: AxiosResponse) => {
								let files_data = {}
								for (let index = 0; index < files.length; index++) {
									const element = files[index];
									if (response2.data[element]) {
										const res = await fetch(response2.data[element]) // add retry may
										files_data[element] = await res.blob()
										
									}
									
								}

								let match_images = {}
								for (let index = 0; index < match_images_key.length; index++) {
									const e = match_images_key[index];
									if(response2.data[e] )
									{
										console.log(e);
										
										const res = await fetch(response2.data[e]) // add retry may
										match_images[e] = await res.blob()
									}
									
								}
								


								await db.challenge.add({
									id: element.challenge,
									data: response2.data,
									...files_data,
									...match_images
								})
								console.log("content downlaoding ");
								ipadStore.counterContentDownload ++;
							})
							.catch((e: any) => {
								console.log(e)
								// CAN NOT dowload data conent
								// TODO
								// MAY RETRY ? and add flag on UI  (RETRY )
								ipadStore.errorDownloaded = true
								ipadStore.dataDownloadedModal = false
							})
					}
					else
					{
						ipadStore.counterContentDownload ++;
					}
				})
			})
			
		
		.catch((e: any) => {
			console.log(e)
			ipadStore.errorDownloaded = true
			ipadStore.dataDownloadedModal = false
		})

}

const getContentDataBase = async (id: number) => {
	const db = initDB()

	
	const content = await db.challenge.where("id").equals(id).first()

	if (content == undefined) {
		console.log("not find")

		return {}
	}
	for (let index = 0; index < files.length; index++) {
		const element = files[index];
		if (content[element]) {
			content[element + '_url'] = URL.createObjectURL(content[element])
			
		}
	}
	// check if have blob and rreturn url
	for (let index = 0; index < match_images_key.length; index++) {
		const e = match_images_key[index];
		if(content[e] )
		{
			content[e +"_url"] =URL.createObjectURL(content[e])
		}
	}
	return content
}

// create a function to store data and remove once is upload
// also to create a function to save

const sendAnswers = async (data, challengeId) => {
	if( challengeId == -1)
	{
		// IF id == -1 is that is in preview mode
		return;
	}
	const db = initDB()
	const eventStore = useEventsStore()
	// eventStore.current_ipad_team.challenges.find(e => e.id == challengeId).done = true

	await db.answers.add({
		challenge: challengeId,
		answer: data,
		team_id: eventStore.current_team.id,
	})

	await db.challenge_done.add({
		challenge: challengeId
	})
	
	await db.table("challenge_started").where('challenge').equals(challengeId).modify(item => {
		item.done = true
	})
	const ipadStore = useIpadHuntStore();
	ipadStore.updateStoreChallengeToStartDb();
	sound_alert("finish_challange");
	// Check local answers???
}

const sendFile = async (data, challengeId,name) => {
	if( challengeId == -1)
	{
		// IF id == -1 is that is in preview mode
		return;
	}
	const db = initDB()
	const eventStore = useEventsStore()
	await db.file_upload.add({
		challenge: challengeId,
		file: data,
		team_id: eventStore.current_team.id,
		name
	})
	await db.challenge_done.add({
		challenge: challengeId
	})
	await db.table("challenge_started").where('challenge').equals(challengeId).modify(item => {
		item.done = true
	})
	const ipadStore = useIpadHuntStore();
	ipadStore.updateStoreChallengeToStartDb();
	sound_alert("finish_challange");
}

const getChallengeDone = async () => {
	const db = initDB()
	const data = await db.challenge_done.toArray()

	return data.map(obj => obj.challenge)
}


const updateData = async (api_key_event: string) => {
	// check if there is any data to upload

	const db = initDB()
	const answers = await db.answers.toArray()

	for (let index = 0; index < answers.length; index++) {
		const e = answers[index]
		await simpleAxios("post", "IpadHunt/submit_answer/" + api_key_event, e)
			.then(async (response: AxiosResponse) => {
				db.transaction("rw", db.answers, async function () {
					db.answers.delete(e.challenge)
				})
				// answer upload remove data base
			})
			.catch(async (error: any) => {
			})
	}

	const file_upload = await db.file_upload.toArray()
	for (let index = 0; index < file_upload.length; index++) {
		const e = file_upload[index]
		const data = new FormData();
		data.append("team_id", e.team_id )
		data.append("challenge", e.challenge )
		data.append("file", e.file, e.name )

		await simpleAxios("post", "IpadHunt/submit_answer/" + api_key_event, data, {
			"Content-Type": "multipart/form-data"
		})
			.then(async (response: AxiosResponse) => {
				db.transaction("rw", db.file_upload, async function () {
					db.file_upload.delete(e.challenge)
				})
				// answer upload remove data base
			})
			.catch(async (error: any) => {})
	}
}

function degreesToRadians(degrees) {
	return (degrees * Math.PI) / 180
}

function haversineDistance(lat1, lon1, lat2, lon2) {
	// Convert decimal degrees to radians
	lat1 = degreesToRadians(lat1)
	lon1 = degreesToRadians(lon1)
	lat2 = degreesToRadians(lat2)
	lon2 = degreesToRadians(lon2)

	// Haversine formula
	const dlon = lon2 - lon1
	const dlat = lat2 - lat1
	const a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	const radiusOfEarth = 6371 // Radius of the Earth in kilometers
	const distance = radiusOfEarth * c * 1000 // Convert to meters
	return distance
}

function isPointInsideCircle(centerLat, centerLon, pointLat, pointLon, radius) {
	// Calculate the distance between the center point and the given point
	const distance = haversineDistance(centerLat, centerLon, pointLat, pointLon)

	// Check if the distance is less than or equal to the radius
	return distance <= radius
}

function getMarkerHtml(must, number) {}

const deleteAllcontent = async () => {
	const dbs = await window.indexedDB.databases()
	dbs.forEach(db => {
		window.indexedDB.deleteDatabase(db.name)
	})
	location.reload()
}

const activeChallenge = async(data)=>{
	// create new challenge data.
	const db = initDB()
	let currentDate = new Date();
	const content = await db.challenge.where("id").equals(data.challenge).first()
	// Add 60 seconds to the current date
	let futureDate = new Date(currentDate.getTime() + content.data.time*1000);
	await db.challenge_started.add({
		id:Math.floor(100000 + Math.random() * 900000),
		done: false,
		challenge: data.id,
		start: true,
		force:false,
		start_time:futureDate, 
	})
	const ipadStore = useIpadHuntStore();
	ipadStore.updateStoreChallengeToStartDb();
}

const getActiveChallenge = async()=>{
	// create new challenge data.
	const db = initDB()
	return await db.challenge_started.toArray()
} 



export {
	checkContentDownloaded,
	getContentDataBase,
	sendAnswers,
	sendFile,
	updateData,
	getChallengeDone,
	isPointInsideCircle,
	deleteAllcontent, 
	activeChallenge, 
	getActiveChallenge
}
